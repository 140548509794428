import { storage } from "./storage";
import { v4 as uuidv4 } from "uuid";
function Util() {
  this.endPoint = "https://service.phopis.com/aapi/v1";
  this.paymentCharges = 45;

  this.getHeaders = () => {
    return {
      "Content-Type": "application/json",
      Accept: "application/json; charset=utf-8",
    };
  };
  this.getAuthorizedHeaders = () => {
    return {
      "Content-Type": "application/json",
      Accept: "application/json; charset=utf-8",
      Authorization: `Bearer ${storage.get("userToken")}`,
    };
  };

  this.getAuthorizedFileHeaders = () => {
    return {
      "Content-Type": "multipart/form-data",
      Accept: "application/json; charset=utf-8",
      Authorization: `Bearer ${storage.get("userToken")}`,
    };
  };

  this.getRandomString = () => {
    const id = uuidv4();
    const uid = id.split("-").join("");
    return uid.substring(0, 11).toLowerCase();
  };
  this.getSerialNumber = () => {
    return (Math.random() + 1).toString(36).substring(2);
  };
  this.getBanks = () => {
    return [
      "Select bank...",
      "Zenith Bank",
      "Guaranty Trust Bank",
      "First Bank of Nigeria",
      "Ecobank Nigeria",
      "Access Bank",
      "United Bank of Nigeria",
      "Union Bank of Nigeria",
      "Fidelity Bank",
    ];
  };
  this.getColors = () => {
    return [
      "Select color...",
      "White",
      "Yellow",
      "Blue",
      "Red",
      "Green",
      "Black",
      "Brown",
      "Azure",
      "Ivory",
      "Teal",
      "Silver",
      "Purple",
      "Navy blue",
      "Pea green",
      "Gray",
      "Orange",
      "Maroon",
      "Charcoal",
      "Aquamarine",
      "Coral",
      "Fuchsia",
      "Wheat",
      "Lime",
      "Crimson",
      "Khaki",
      "Hot pink",
      "Magenta",
      "Olden",
      "Plum",
      "Olive",
      "Cyan",
    ];
  };
  this.getContacts = () => {
    return ["EMAIL", "PHONE"];
  };
  this.getsSocialLink = () => {
    return ["LINKEDIN", "FACEBOOK", "INSTAGRAM", "TWITTER", "WEBSITE"];
  };
  this.formatDate = (t) => {
    const date = ("0" + t.getDate()).slice(-2);
    const month = ("0" + (t.getMonth() + 1)).slice(-2);
    const year = t.getFullYear();
    return `${year}-${month}-${date}`;
  };

  this.validMembershipId = (membershipId) => {
    return (
      membershipId &&
      membershipId !== "" &&
      membershipId.toUpperCase().startsWith("A") &&
      membershipId.length >= 11
    );
  };

  this.decodeCycle = (cycle) => {
    if (cycle === "DAILY") {
      const date = this.formatDate(new Date());
      return {
        from: date,
        to: date,
      };
    } else if (cycle === "MONTHLY") {
      const t = new Date();
      const date = ("0" + t.getDate()).slice(-2);
      const month = ("0" + (t.getMonth() + 1)).slice(-2);
      const year = t.getFullYear();
      return {
        from: `${year}-${month}-01`,
        to: `${year}-${month}-${date}`,
      };
    } else if (cycle === "YEARLY") {
      const t = new Date();
      const date = ("0" + t.getDate()).slice(-2);
      const month = ("0" + (t.getMonth() + 1)).slice(-2);
      const year = t.getFullYear();
      return {
        from: `${year}-01-01`,
        to: `${year}-${month}-${date}`,
      };
    } else {
      const date = this.formatDate(new Date());
      return {
        from: date,
        to: date,
      };
    }
  };
}
const util = new Util();
export { util };
