import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Footer from './components/Footer';
import NavBar from './components/NavBar';
import SideBar from './components/SideBar';
import Logo from '../../assets/img/logoWhite.png';
import { storage } from '../../utils/constants/storage';
import { useContext } from 'react';
import { UserContext } from '../../Access';
import Avatar from '../../assets/img/avatar.png';
import { ToastContainer, toast } from 'react-toastify';
import { titleCase } from 'title-case';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CopyToClipboard from 'react-copy-to-clipboard';

const OtherPageBody = ({ children }) => {
  const [navOpen, setNavOpen] = useState(false);
  const userSession = useContext(UserContext);
  const [showProfile, setShowProfile] = useState(false);
  const [openThemeModal, setOpenThemeModal] = useState(false);
  const user = userSession.user;
  const navigate = useNavigate();

  let nav = {
    open: 'wf-flaticon-n4-inactive wf-lato-n7-inactive wf-lato-n3-inactive wf-lato-n4-inactive wf-fontawesome5solid-n4-active wf-fontawesome5regular-n4-active wf-simplelineicons-n4-active wf-fontawesome5brands-n4-active wf-lato-n9-inactive wf-active nav_open',
    close:
      'wf-flaticon-n4-inactive wf-lato-n7-inactive wf-lato-n3-inactive wf-lato-n4-inactive wf-fontawesome5solid-n4-active wf-fontawesome5regular-n4-active wf-simplelineicons-n4-active wf-fontawesome5brands-n4-active wf-lato-n9-inactive wf-active ', //topbar_open
  };

  const handleOpen = () => {
    navigate(-1);
  };
  const logout = () => {
    storage.remove('userToken');
    storage.remove('user');
    toast.warn('Logging out...');
    setTimeout(() => {
      window.location = '/user/login';
    }, 500);
  };

  const lockScreen = () => {
    storage.save('locked', 'yes');
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const changeTheme = (theme) => {
    storage.save('theme', theme);
    setOpenThemeModal(false);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <div className={navOpen ? nav['open'] : nav['close']}>
      <ToastContainer />
      <Dialog open={openThemeModal}>
        <DialogTitle>Select Theme</DialogTitle>
        <DialogContent>
          <div className="theme-option">
            <li onClick={() => changeTheme('grayscale(1)')}>Dark</li>
            <li onClick={() => changeTheme('hue-rotate(45deg)')}>Hue</li>
            <li onClick={() => changeTheme('invert(1)')}>Invert</li>
            <li onClick={() => changeTheme('sepia(1)')}>Sepia</li>
            <li onClick={() => changeTheme('saturate(0.5)')}>Saturate</li>
            <li onClick={() => changeTheme('unset')}>Default</li>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpenThemeModal(false);
            }}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Cancel
          </button>
        </DialogActions>
      </Dialog>
      <div className="wrapper">
        <div className="main-header">
          {/* <!-- Logo Header --> */}
          <div className="logo-header" data-background-color="blue">
            <Link to="/" className="logo">
              <img src={Logo} alt="ammon" className="navbar-brand" />
            </Link>
            <button
              className="navbar-toggler sidenav-toggler ml-auto"
              type="button"
              data-toggle="collapse"
              data-target="collapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={handleOpen}
            >
              <span className="navbar-toggler-icon">
                <i className="fa fa-chevron-left"></i>
              </span>
            </button>
            <button
              className="topbar-toggler more"
              onClick={() => {
                setShowProfile(!showProfile);
              }}
            >
              <i className="icon-options-vertical"></i>
            </button>
            <div className="nav-toggle">
              <button className="btn btn-toggle toggle-sidebar">
                <i className="icon-menu"></i>
              </button>
            </div>
          </div>
          <ul
            className={
              'dropdown-menu dropdown-user animated fadeIn' +
              (showProfile ? ' show' : '')
            }
          >
            <div className="dropdown-user-scroll scrollbar-outer">
              <li>
                <div className="user-box">
                  <div className="avatar-lg">
                    <img src={Avatar} alt="/" className="avatar-img rounded" />
                  </div>
                  <div className="u-text">
                    <h4>{titleCase(user.name.toLowerCase())}</h4>
                    <p className="text-muted">
                      Account :{user.code.toString().substring(0, 2)}****
                    </p>
                    <Link
                      to="/user/profile"
                      className="btn btn-xs btn-secondary btn-sm"
                    >
                      View Profile
                    </Link>
                  </div>
                </div>
              </li>
              <li>
                <div className="dropdown-divider"></div>

                <Link className="dropdown-item" to="/user/reset-password">
                  Change Password
                </Link>

                <div className="dropdown-divider"></div>
                <div
                  className="dropdown-item"
                  onClick={() => setOpenThemeModal(true)}
                >
                  Change Theme
                </div>

                <div className="dropdown-divider"></div>
                <div className="dropdown-item" onClick={() => lockScreen()}>
                  Lock Screen
                </div>

                <div className="dropdown-divider"></div>
                <CopyToClipboard text={'https://ammon.phopis.com/user/register/business/?referee=' + user.code} onCopy={() => {
                  toast.success('Referral Link Copied...');
                }}>
                  <div className="dropdown-item" >
                    Copy Referral Link
                  </div>
                </CopyToClipboard>

                <div className="dropdown-divider"></div>
                <div className="dropdown-item" onClick={() => logout()}>
                  Logout
                </div>
              </li>
            </div>
          </ul>
          {/* <!-- End Logo Header -->

			<!-- Navbar Header --> */}
          <NavBar />
          {/* <!-- End Navbar --> */}
        </div>

        {/* <!-- Sidebar --> */}
        <SideBar />
        {/* <!-- End Sidebar --> */}

        <div className="main-panel">
          <div className="content">{children}</div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default OtherPageBody;
