import { util } from "../utils/constants/constants";
import { http } from "../utils/constants/http";

let fileService = {
  uploadFile: async (formData) => {
    console.log(formData)
    return await http.post(`${util.endPoint}/file/upload`, formData, {
      headers: util.getAuthorizedFileHeaders(),
    });
  },
  uploadFiles: async (formData) => {
    console.log(formData)
    return await http.post(`${util.endPoint}/file/uploads`, formData, {
      headers: util.getAuthorizedFileHeaders(),
    });
  },
  uploadSilentFiles: async (formData) => {
    console.log(formData)
    return await http.post(`${util.endPoint}/file/silent/uploads`, formData, {
      headers: util.getHeaders(),
    });
  },

};
export { fileService };
