import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { unitService } from '../../service/unit.service';
import toMoney from 'number-to-money';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputField from './components/inputs/InputField';
import TextArea from './components/inputs/TextArea';
import Loader from './../users/components/loaders/Loader';
import { util } from '../../utils/constants/constants';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { Link } from 'react-router-dom';
import Chart from 'react-apexcharts';
import { inventoryService } from '../../service/inventory.service';
import { UserType } from '../../enums/UserType';
import { resourceService } from '../../service/resource.service';
import { userService } from '../../service/user.service';
import { storage } from '../../utils/constants/storage';
import { UserContext } from '../../Access';
import { UserRole } from '../../enums/UserRole';
import SearchField from './components/inputs/SearchField';
import TinyLoader from './components/loaders/TinyLoader';
import { titleCase } from 'title-case';

const DashBody = () => {
  const userSession = useContext(UserContext);
  const user = userSession.user;

  const [cycle, setCycle] = useState(storage.get('cycle'))
  const [searchText, setSearchText] = useState('');
  const [searchResult, setSearchResult] = useState(null);
  const [searchProgress, setSearchProgress] = useState(false);

  const [inventoryChartSeries, setInventoryChartSeries] = useState([
    {
      name: 'Transaction Analytics',
      data: [
        0, 0, 0
      ],
    },
  ]);
  const [inventoryChartOptions, setInventoryChartOptions] = useState({
    chart: {
      id: 'inventory-analytics',
    },
    xaxis: {
      categories: [
        'Total',
        'Revenue',
        'Discount',
      ],
    },
  });

  const [unitChartSeries, setUnitChartSeries] = useState([
    {
      name: 'Unit Analytics',
      data: [0, 0, 0],
    },
  ]);
  const [unitChartOptions, setUnitChartOptions] = useState({
    chart: {
      id: 'unit-analytics',
    },
    xaxis: {
      categories: ['Total Balance', 'Total Credit', 'Total Debit'],
    },
  });

  const [pin, setPin] = useState();
  const [unit, setUnit] = useState();
  const [unitLogs, setUnitLogs] = useState(null);
  const [unitAnalytics, setUnitAnalytics] = useState();
  const [inventoryAnalytic, setInventoryAnalytic] = useState();
  const [unitProgress, setUnitProgress] = useState();
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [openWithdrawModal, setOpenWithdrawModal] = useState(false);
  const [openPinModal, setOpenPinModal] = useState(false);
  const [openVerifyPinModal, setOpenVerifyPinModal] = useState(false);
  const [openFundingModal, setOpenFundingModal] = useState(false);
  const [transferProgress, setTransferProgress] = useState(false);
  const [withdrawProgress, setWithdrawProgress] = useState(false);
  const [createPinProgress, setCreatePinProgress] = useState(false);
  const [verifyPinProgress, setVerifyPinProgress] = useState(false);
  const [fundingProgress, setFundingProgress] = useState(false);
  const [paymentRef, setPaymentRef] = useState(util.getRandomString());
  const [publicKey, setPublicKey] = useState(
    'FLWPUBK-df12d779e5753b10ab7781ff187230c8-X',
  );
  const [transferInput, setTransferInput] = useState({
    recipient: '',
    amount: '',
    narration: '',
  });
  const [withdrawInput, setWithdrawInput] = useState({
    accountType: '',
    accountName: '',
    accountNumber: '',
    amount: '',
  });
  const [fundingInput, setFundingInput] = useState({
    amount: '',
  });

  const config = {
    public_key: publicKey,
    tx_ref: `uni${paymentRef}`,
    amount: Number(fundingInput.amount) + Number(util.paymentCharges),
    currency: 'NGN',
    customer: {
      email: 'support@ammon.com',
      phone_number: user.phoneNumber,
      name: user.name,
    },
    customizations: {
      title: 'AMMON Unit Funding',
      description: 'Payment for unit funding',
      logo: 'https://ammon.phopis.com/icon.png',
    },
  };
  const handleFlutterPayment = useFlutterwave(config);
  const closeTransferModal = () => {
    setOpenTransferModal(false);
  };
  const closeFundingModal = () => {
    setOpenFundingModal(false);
  };
  const closeWithdrawModal = () => {
    setOpenWithdrawModal(false);
  };
  const performTransfer = () => {
    if (transferInput.recipient === '' || transferInput.recipient.length < 3)
      toast.error('Enter valid recipient address');
    else if (transferInput.amount === '' || transferInput.amount <= 0)
      toast.error('Enter valid amount');
    else if (transferInput.narration === '') toast.error('Enter Narration');

    if (
      transferInput.recipient !== '' &&
      transferInput.recipient.length >= 3 &&
      transferInput.amount !== '' &&
      transferInput.amount > 0 &&
      transferInput.narration !== ''
    ) {
      const request = {
        recipient: transferInput.recipient,
        narration: transferInput.narration,
        amount: Number(transferInput.amount),
      };

      setTransferProgress(true);
      unitService
        .fundsTransfer(request)
        .then((result) => {
          setTransferProgress(false);
          if (result.data.success) {
            getUnitDetails();
            toast.success('Transfer successful');
            setOpenTransferModal(false);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setTransferProgress(false);
        });
    }
  };

  const performWithdrawal = () => {
    if (withdrawInput.accountType === '' || withdrawInput.accountType === 'Select Bank...')
      toast.error('Select Bank  Name');
    else if (withdrawInput.accountName === '' || withdrawInput.accountName.length < 3)
      toast.error('Provide Valid  Account Name');
    else if (withdrawInput.accountNumber === '' || withdrawInput.accountNumber.length !== 10)
      toast.error('Provide valid Account Number');
    else if (withdrawInput.amount === '' || withdrawInput.amount.length < 0)
      toast.error('Provide amount to withdraw');

    if (
      withdrawInput.accountType !== '' &&
      withdrawInput.accountType !== 'Select Bank...' &&
      withdrawInput.accountNumber !== '' &&
      withdrawInput.accountNumber.length === 10 &&
      withdrawInput.accountName !== '' &&
      withdrawInput.accountName.length >= 3 &&
      withdrawInput.amount !== '' &&
      withdrawInput.amount.length > 0
    ) {

      const request = {
        accountType: withdrawInput.accountType,
        accountName: withdrawInput.accountName,
        accountNumber: withdrawInput.accountNumber,
        amount: Number(withdrawInput.amount),
      };

      setWithdrawProgress(true);
      unitService.withdrawFunds(request)
        .then((result) => {
          setWithdrawProgress(false);
          if (result.data.success) {
            getUnitDetails();
            toast.success('Request successful');
            setOpenWithdrawModal(false);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setWithdrawProgress(false);
        });
    }
  }
  const performFunding = () => {
    if (fundingInput.amount === '' || fundingInput.amount <= 0)
      toast.error('Enter amount');

    if (fundingInput.amount !== '' && fundingInput.amount > 0) {
      setPaymentRef(`uni${util.getRandomString()}`);
      setPublicKey('FLWPUBK-df12d779e5753b10ab7781ff187230c8-X');

      const request = {
        paymentRef: paymentRef,
        channel: 'Funding',
        amount: Number(fundingInput.amount) + Number(util.paymentCharges),
      };
      toast.success('Processing your request...');

      handleFlutterPayment({
        callback: (response) => {
          console.log(response);
          if (response.status && response.status === 'completed') {
            request.paymentRef = response.tx_ref;
            request.transactionId = response.transaction_id;
            setFundingProgress(false);
            setOpenTransferModal(false);
            toast.success('Payment successful');
            getUnitDetails();
          } else {
            toast.error(response.status);
          }
          closePaymentModal();
        },
        onClose: () => { },
      });
    }
  };


  const getTransactionAnalytics = () => {
    inventoryService
      .getTransactionAnalytics()
      .then((response) => {
        if (response.data.success) {
          const data = response.data.data;

          setInventoryAnalytic(data);
          const options = inventoryChartOptions.xaxis.categories;
          const series = [
            {
              name: 'Transaction Analytics',
              data: [
                data.totalAmount,
                data.totalRevenue,
                data.totalDiscount
              ],
            },
          ];

          if (user.role === UserRole.User && user.accountType === UserType.Business) {
            const newOptions = options.slice(0, -2);
            const newSeries = series[0].data.slice(0, -2);

            series[0].data = newSeries;
            inventoryChartOptions.xaxis.categories = newOptions;

            setInventoryChartSeries(series);
            setInventoryChartOptions(inventoryChartOptions);
          } else {
            setInventoryChartSeries(series);
          }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const getUnitAnalytics = () => {
    setUnitProgress(true);
    unitService
      .getUnitAnalytics()
      .then((response) => {
        setUnitProgress(false);
        if (response.data.success) {
          const data = response.data.data;
          const newSeries = [
            {
              name: 'Unit Analytics',
              data: [
                data.totalCredit - data.totalDebit,
                data.totalCredit,
                data.totalDebit,
              ],
            },
          ];
          setUnitAnalytics(data);
          setUnitChartSeries(newSeries);

          console.log('series', newSeries);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => setUnitProgress(false));
  };
  const getUnitLogs = () => {
    setUnitProgress(true);
    unitService
      .getUnitLogs()
      .then((response) => {
        if (response.data.success) {
          setUnitLogs(response.data.data);
          setUnitProgress(false);
          getUnitAnalytics();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        setUnitProgress(false);
      });
  };
  const getUnitDetails = () => {
    setUnitProgress(true);
    unitService
      .getUnit()
      .then((response) => {
        setUnitProgress(false);
        if (response.data.success) {
          setUnit(response.data.data);
          getUnitLogs();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        setUnitProgress(false);
      });
  };

  const createPin = () => {
    if (pin && pin.length < 4) toast.error('Invalid PIN');

    if (pin && pin.length >= 4) {
      setCreatePinProgress(true);
      userService
        .createPIN(pin)
        .then((response) => {
          setCreatePinProgress(false);
          if (response.data.success) {
            const user = response.data.data;
            userSession.setUser(user);
            storage.save('user', JSON.stringify(user));
            toast.success('Pin created successful');
            setOpenPinModal(false);
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((err) => {
          setCreatePinProgress(false);
        });
    }
  };

  const verifyPin = () => {
    if (pin && pin.length < 4) toast.error('Invalid PIN');

    if (pin && pin.length >= 4) {
      setVerifyPinProgress(true);
      userService
        .verifyPin(pin)
        .then((response) => {
          setVerifyPinProgress(false);
          if (response.data.success) {
            setOpenPinModal(false);
            performTransfer();
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((err) => {
          setVerifyPinProgress(false);
        });
    }
  };

  const performSearch = () => {
    if (searchText && searchText.length >= 2) {
      setSearchProgress(true);
      userService.searchBusinesses(searchText)
        .then((result) => {
          setSearchProgress(false);
          if (result.data.success) {
            setSearchResult(result.data.data.page);
          } else {
            setSearchResult(null);
          }
        })
        .catch((e) => {
          setSearchProgress(false);
          setSearchResult(null);
        });
    } else {
      setSearchResult(null);
    }
  }

  const onSearch = (e) => {
    setSearchText(e.target.value);
    performSearch();
  };
  useEffect(() => {
    if (
      !user.pin ||
      user.pin == null ||
      user.pin === undefined ||
      user.pin === ''
    ) {
      setOpenPinModal(true);
    }
    getUnitDetails();
    getTransactionAnalytics();
  }, []);


  return (
    <>
      <div className="panel-header bg-primary-gradient">
        <Dialog open={openPinModal}>
          <DialogTitle>Create PIN</DialogTitle>
          <DialogContent>
            <InputField
              type="password"
              name="pin"
              value={pin}
              maxLength="4"
              autoComplete="off"
              placeholder="****"
              label="Provide your favorite 4 digit PIN"
              className="form-control pin-input"
              onChange={(e) => setPin(e.target.value)}
              required
            />
          </DialogContent>
          <DialogActions>
            <button
              className="btn btn-primary btn-round"
              onClick={createPinProgress !== true ? createPin : null}
            >
              {createPinProgress === true ? <TinyLoader /> : 'Create PIN'}
            </button>
          </DialogActions>
        </Dialog>

        <Dialog open={openVerifyPinModal}>
          <DialogTitle>Verify PIN</DialogTitle>
          <DialogContent>
            <InputField
              type="password"
              name="pin"
              value={pin}
              maxLength="4"
              autoComplete="off"
              placeholder="****"
              label="Provide your 4 digit PIN"
              className="form-control pin-input"
              onChange={(e) => setPin(e.target.value)}
              required
            />
          </DialogContent>
          <DialogActions>
            <button
              className="btn btn-primary btn-round"
              onClick={verifyPinProgress === true ? verifyPin : null}
            >
              {verifyPinProgress === true ? <TinyLoader /> : 'Continue'}
            </button>
          </DialogActions>
        </Dialog>

        <Dialog open={openTransferModal}>
          <DialogTitle>Unit Transfer</DialogTitle>
          <DialogContent>
            <InputField
              type="text"
              name="recipient"
              value={transferInput.recipient}
              maxLength="6"
              placeholder="Recipient Address"
              label="Recipient unit address"
              onChange={(e) =>
                setTransferInput({
                  ...transferInput,
                  recipient: e.target.value,
                })
              }
              required
            />
            <InputField
              type="number"
              name="amount"
              value={transferInput.amount}
              placeholder="Amount you want to transfer"
              label="Enter Amount"
              onChange={(e) =>
                setTransferInput({
                  ...transferInput,
                  amount: e.target.value,
                })
              }
              required
            />
            <TextArea
              name="narration"
              label="Narration"
              value={transferInput.narration}
              placeholder="Remark"
              onChange={(e) =>
                setTransferInput({
                  ...transferInput,
                  narration: e.target.value,
                })
              }
              required
            />
          </DialogContent>
          <DialogActions>
            <button
              onClick={closeTransferModal}
              className="btn btn-primary btn-border btn-round mr-2"
            >
              Cancel
            </button>
            <button
              onClick={() => !transferProgress ? setOpenPinModal(true) : null}
              className="btn btn-primary btn-round"
            >
              {transferProgress ? <TinyLoader /> : 'Continue'}
            </button>
          </DialogActions>
        </Dialog>



        <Dialog open={openFundingModal}>
          <DialogTitle>Buy Unit</DialogTitle>
          <DialogContent>
            <InputField
              type="number"
              value={fundingInput.amount}
              label="Enter Amount"
              placeholder="Amount"
              onChange={(e) =>
                setFundingInput({
                  ...fundingInput,
                  amount: e.target.value,
                })
              }
              required
            />
          </DialogContent>
          <DialogActions>
            <button
              onClick={closeFundingModal}
              className="btn btn-primary btn-border btn-round mr-2"
            >
              Cancel
            </button>
            <button
              onClick={
                !fundingProgress
                  ? performFunding
                  : null
              }
              className="btn btn-primary btn-round"
            >
              {fundingProgress ? <TinyLoader /> : 'Continue'}
            </button>
          </DialogActions>
        </Dialog>
        <ToastContainer />
        <div className="page-inner py-5">
          <div className="align-items-left align-items-md-center flex-column flex-md-row">
            <div>

              <div className="public-search mb-4">
                <SearchField
                  onChange={onSearch}
                  placeholder="Search POS center around you..."
                />
                <div className="nav-search-result" style={{ display: !searchResult && !searchProgress ? 'none' : 'block' }}>
                  {searchProgress ? <div className="loader-wrapper text-align-center"><Loader /></div> :
                    <ul className="result-set">
                      {searchResult && searchResult.length > 0 ?
                        searchResult.map((business, index) => {
                          return <li key={index}>
                            <a target="_blank" rel="noreferrer" href={business.alias ? 'https://phopis.com/' + business.alias : '#'}>{business.name}<p className="sub-search-text">{business.businessType} at {business.address} {business.state}</p></a></li>
                        })
                        : <>Search not found</>}
                    </ul>
                  }
                </div>
              </div>
              <h2 className="text-white pb-2 fw-bold">Welcome to AMMON</h2>
              <h5 className="text-white op-7 mb-2">
                Manage all your mobile money businesses in one place
              </h5>
            </div>

            <div className="d-flex py-2 overflow-x body-action-buttons">
              <Link to="/service/oder/deposit">
                <button className="btn btn-secondary mr-2  btn-round long-button">
                  <i className="fas fa-plus"></i> Deposit
                </button>
              </Link>
              <Link to="/service/oder/withdrawal">
                <button className="btn btn-white btn-white btn-round long-button">
                  <i className="fa fa-minus"></i> Withdrawal
                </button>
              </Link>
              &nbsp;&nbsp;
              <Link to="/service/oder/billpayment">
                <button className="btn  btn-danger btn-round long-button">
                  <i className="fa fa-tag"></i> Bill Payment
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="page-inner mt--5">
        <div className="row mt--2">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                {user.accountType !== UserType.Individual ? (
                  <>
                    {user.subscription ? (
                      <>
                        <h5 className="font-size24 sm-font-size22 xs-font-size20">
                          Subscription Summary
                        </h5>
                        <div className="mb-3 text-danger">
                          {user.subscription.daysLeft
                            ? 100 - (365 - user.subscription.daysLeft) / 100
                            : 'X'}
                          % Left
                        </div>

                        <div className="d-flex card-title">
                          <span>{cycle ? titleCase(cycle.toLowerCase()) : 'Daily'} Statistics &nbsp;</span>
                          <Link className="btn link-btn text-primary" to="/inventory/analytics"><i className="fa fa-eye"></i>View Report</Link>
                        </div>

                        {inventoryAnalytic ? (
                          <div className="row mb-2">
                            <div className="col break-item">
                              <div>
                                <h6 className="fw-bold text-uppercase text-success op-8">
                                  Total Trans
                                </h6>
                                <h4 className="fw-bold">
                                  ₦{' '}
                                  {inventoryAnalytic.totalAmount
                                    ? toMoney(
                                      inventoryAnalytic.totalAmount
                                    )
                                    : '0.00'}
                                </h4>
                              </div>
                            </div>

                            <div className="col break-item">
                              <div>
                                <h6 className="fw-bold text-uppercase text-success op-8">
                                  Total Revenue
                                </h6>
                                <h4 className="fw-bold">
                                  ₦{' '}

                                  {user.role !== UserRole.User ? inventoryAnalytic.totalRevenue
                                    ? toMoney(
                                      inventoryAnalytic.totalRevenue,
                                    )
                                    : '0.00' : '****'}
                                </h4>
                              </div>
                            </div>
                            <div className="col break-item">
                              <div>
                                <h6 className="fw-bold text-uppercase text-danger op-8">
                                  Total Discount
                                </h6>
                                <h4 className="fw-bold">
                                  ₦{' '}
                                  {inventoryAnalytic.totalDiscount
                                    ? toMoney(
                                      inventoryAnalytic.totalDiscount,
                                    )
                                    : '0.00'}
                                </h4>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}

                    <div className="card-category">
                      See what you sold, earn and whats left.
                    </div>
                    <Chart
                      options={inventoryChartOptions}
                      series={inventoryChartSeries}
                      type="area"
                      width={100 + '%'}
                    />
                  </>
                ) :
                  <></>
                }
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              {unitProgress ? (
                <Loader></Loader>
              ) : (
                <div className="card-body">
                  <div className="card-title">
                    Unit Overview{' '}
                    <div className="unit-id user-code">
                      <i
                        className="copy-btn fa fa-copy"
                        onClick={() => {
                          navigator.clipboard.writeText(unit.code);
                          toast.success('Copied');
                        }}
                      ></i>{' '}
                      {unit ? unit.code : ''}
                    </div>
                  </div>

                  <div className="row py-3">
                    <div className="col break-item">
                      <div>
                        <h6 className="fw-bold text-uppercase text-success op-8">
                          Balance
                        </h6>
                        <h4 className="fw-bold">
                          <del className="phopis-unit">P</del>{unit ? toMoney(unit.balance) : '0.00'}
                        </h4>
                      </div>
                    </div>
                    <div className="col break-item">
                      <div>
                        <h6 className="fw-bold text-uppercase text-danger op-8">
                          Total Debit
                        </h6>
                        <h4 className="fw-bold">
                          <del className="phopis-unit">P</del>
                          {unitAnalytics
                            ? toMoney(unitAnalytics.totalDebit)
                            : '0.00'}
                        </h4>
                      </div>
                    </div>
                    <div className="col break-item">
                      <div>
                        <h6 className="fw-bold text-uppercase text-success op-8">
                          Total Credit
                        </h6>
                        <h4 className="fw-bold">
                          <del className="phopis-unit">P</del>
                          {unitAnalytics
                            ? toMoney(unitAnalytics.totalCredit)
                            : '0.00'}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="ml-md-auto py-2 py-md-0">
                    <button
                      onClick={() => {
                        setOpenTransferModal(true);
                      }}
                      className="btn btn-primary btn-round mr-2 mb-2"
                    >
                      <i className="fa fa-arrow-right"></i> Transfer Unit
                    </button>

                    <button
                      className="btn btn-secondary btn-round mr-2 mb-2"
                      onClick={() => {
                        setOpenFundingModal(true);
                      }}
                    >
                      <i className="fa fa-plus"></i> Buy Unit
                    </button>
                  </div>
                  <Chart
                    options={unitChartOptions}
                    series={unitChartSeries}
                    type="area"
                    width={100 + '%'}
                  />
                </div>
              )}
            </div>
          </div>
        </div>













        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                More you can do with ammon
              </div>
              <div className="card-body">
                <ol className="activity-feed">
                  <li className="feed-item feed-item-secondary">
                    <time className="date" dateTime="9-25">
                      1
                    </time>
                    <span className="text">
                    Manage your customers and businesses
                    </span>
                  </li>
                  <li className="feed-item feed-item-success">
                    <time className="date" dateTime="9-24">
                      2
                    </time>
                    <span className="text">
                    Track profits and loses of your Business
                    </span>
                  </li>
                  <li className="feed-item">
                    <time className="date" dateTime="9-17">
                      3
                    </time>
                    <span className="text">
                    Pay your Annual Dues
                    </span>
                  </li>
                  <li className="feed-item feed-item-info">
                    <time className="date" dateTime="9-23">
                      5
                    </time>
                    <span className="text">
                    Record your transactions
                    </span>
                  </li>
                  <li className="feed-item feed-item-warning">
                    <time className="date" dateTime="9-21">
                      6
                    </time>
                    <span className="text">
                    Keep track of your transactions
                    </span>
                  </li>
                  <li className="feed-item feed-item-danger">
                    <time className="date" dateTime="9-18">
                      9
                    </time>
                    <span className="text">
                    Refer others to Register with Ammon
                    </span>
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                Transaction History
              </div>
              <div className="card-body">
                {unitProgress ? (
                  <Loader />
                ) : unitLogs != null ? (
                  <div>
                    <div className="card-body p-0">
                      <div className="table-responsive table-hover table-sales">
                        <table className="table table-striped">
                          <tbody>
                            {unitLogs.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <th>{data.activity}</th>
                                  <td>{data.channel}</td>
                                  <td> {data.createdAt}</td>
                                  <td>
                                    {' '}
                                    <h6
                                      className={
                                        data.activity === 'DEBIT'
                                          ? 'text-danger fw-bold'
                                          : 'text-info fw-bold'
                                      }
                                    >
                                      {data.activity === 'CREDIT' ? '+' : '-'}
                                      <del className="phopis-unit">P</del>
                                      {toMoney(data.amount)}
                                    </h6>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card-body p-0">
                    <div className="table-responsive table-hover table-sales">
                      <p>No Transaction found</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashBody;
