import React, { useState, useEffect } from 'react';
import OtherPageBody from './OtherPageBody';
import { ToastContainer, toast } from 'react-toastify';
import { titleCase } from 'title-case';
import { userService } from '../../service/user.service';
import Loader from './../users/components/loaders/Loader';
import SearchField from './components/inputs/SearchField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputField from './components/inputs/InputField';
import { Status } from '../../enums/Status';
import TextArea from './components/inputs/TextArea';
import Button from './components/button/Button';
import TinyLoader from './components/loaders/TinyLoader';
import OptionField from './components/select/OptionField';
import { location } from '../../utils/constants/location';
import { useContext } from 'react';
import { UserContext } from '../../Access';
import { UserType } from '../../enums/UserType';
const AllUsers = () => {
  const userSession = useContext(UserContext);
  const user = userSession.user;

  const [allUsers, setAllUsers] = useState([]);
  const [totalPages, setTotalPage] = useState();
  const [inProgress, setInProgress] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
  const [activateProgress, setActivateProgress] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [editProgress, setEditProgress] = useState(false);
  const [addProgress, setAddProgress] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [downloadURL, setDownloadURL] = useState();
  const [input, setInput] = useState({ from: "", to: "", city: "", status: "" });
  const [editInput, setEditInput] = useState({
    name: '',
    phoneNumber: '',
    address: '',
  });

  const [addInput, setAddInput] = useState({
    name: '',
    phoneNumber: '',
    address: '',
    nin: '',
    password: '',
  });

  let [currentPage, setCurrentPage] = useState(0);

  const getUsers = () => {
    const request = {
      from: input.from,
      to: input.to,
      city: input.city,
      status: input.status,
    };
    setInProgress(true);
    userService
      .getAllUsers(currentPage, request)
      .then(async (result) => {
        setInProgress(false);
        if (result.data.success) {
          const { page } = result.data.data;
          const { data } = result.data;
          setAllUsers(page);
          if (page.length > 0) {
            setTotalPage(data.totalPages);
            setCurrentPage(data.currentPage);
          } else {
            setAllUsers([])
          }
        } else {
          setAllUsers([])
        }

        const download = await userService.downloadUsers(currentPage, request)
        if (download.data.success)
          setDownloadURL(download.data.data.url)


      })
      .catch((e) => {
        setAllUsers([])
        setInProgress(false);
      });
  };

  const changePage = (action) => {
    if (action === -1) {
      if (currentPage > 0) currentPage--;
    } else if (action === +1) {
      if (currentPage < totalPages) currentPage++;
    } else {
      currentPage = action;
    }
    setCurrentPage(currentPage);
    if (searchText && searchText.length >= 3) performSearch();
    else getUsers();
  };

  const performSearch = () => {
    if (searchText && searchText.length >= 3) {
      const request = {
        from: input.from,
        to: input.to,
        city: input.city,
        status: input.status,
      };
      setInProgress(true);
      userService
        .searchUsers(currentPage, searchText, request)
        .then((result) => {
          setInProgress(false);
          if (result.data.success) {
            const { page } = result.data.data;
            const { data } = result.data;
            setAllUsers(page);
            if (page.length > 0) {
              setTotalPage(data.totalPages);
              setCurrentPage(data.currentPage);
            } else {
              setAllUsers([])
              setCurrentPage([]);
            }
          } else {
            setAllUsers([])
            setAllUsers([]);
          }
        })
        .catch((e) => {
          setAllUsers([]);
          setInProgress(false);
        });
    }
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
    performSearch();
  };

  const createUser = () => {
    if (addInput.name === '' || addInput.name.length < 3)
      toast.error('Valid name required');
    else if (addInput.phoneNumber === '' || addInput.phoneNumber.length < 11)
      toast.error('Valid Phone number required');
    else if (addInput.address === '' || addInput.address.length < 3)
      toast.error('Valid Address required');
    else if (addInput.nin === '' || addInput.nin.length < 11)
      toast.error('Valid NIN required');
    else if (addInput.password === '' || addInput.password.length < 3)
      toast.error('Provide strong password');

    if (
      addInput.name !== '' &&
      addInput.name.length >= 3 &&
      addInput.phoneNumber !== '' &&
      addInput.phoneNumber.length === 11 &&
      addInput.address !== '' &&
      addInput.address.length >= 3 &&
      addInput.nin !== '' &&
      addInput.nin.length >= 11 &&
      addInput.password !== '' &&
      addInput.password.length >= 3
    ) {
      const request = {
        name: addInput.name,
        phoneNumber: addInput.phoneNumber,
        address: addInput.address,
        nin: addInput.nin,
        password: addInput.password,
      };
      setAddProgress(true);
      userService
        .createUser(request)
        .then((result) => {
          setAddProgress(false);
          if (result.data.success) {
            getUsers();
            toast.success('Created successful');
            setOpenAddUserModal(false);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setAddProgress(false);
        });
    }
  };

  const updateUser = () => {
    if (editInput.name === '' || editInput.name.length < 3)
      toast.error('Valid name required');
    else if (editInput.phoneNumber === '' || editInput.phoneNumber.length < 11)
      toast.error('Valid Phone number required');
    else if (editInput.address === '' || editInput.address.length < 3)
      toast.error('Valid Address required');

    if (
      editInput.name !== '' &&
      editInput.name.length >= 3 &&
      editInput.phoneNumber !== '' &&
      editInput.phoneNumber.length === 11 &&
      editInput.address !== '' &&
      editInput.address.length >= 3
    ) {
      setEditProgress(true);
      const request = {
        name: editInput.name,
        phoneNumber: editInput.phoneNumber,
        address: editInput.address,
      };
      userService
        .updateUser(request, selectedUser.uuid)
        .then((result) => {
          setEditProgress(false);
          if (result.data.success) {
            toast.success('Saved successful');
            setOpenEditUserModal(false);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setEditProgress(false);
        });
    }
  };

  const performDeactivate = () => {
    setActivateProgress(true);
    userService
      .deactivateUser(selectedUser.uuid)
      .then((result) => {
        setOpenDeactivateModal(false);
        setActivateProgress(false);
        if (result.data.success) {
          getUsers();
          toast.success('Deactivated successful');
          setOpenDeactivateModal(false);
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.response.data.message);
        setOpenDeactivateModal(false);
        setActivateProgress(false);
      });
  };

  const performActivate = () => {
    setActivateProgress(true);
    toast.success('Activating...');
    userService
      .activateUser(selectedUser.uuid)
      .then((result) => {
        setActivateProgress(false);
        if (result.data.success) {
          getUsers();
          toast.success('Activated successful');
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.response.data.message);
        setActivateProgress(false);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <OtherPageBody>
      <ToastContainer />
      <ToastContainer />

      <Dialog open={openDeactivateModal}>
        <DialogTitle>Deactivate User</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to deactivate this user?</p>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpenDeactivateModal(false);
            }}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            No
          </button>
          <button
            onClick={!activateProgress ? performDeactivate : null}
            className="btn btn-primary btn-round"
          >
            {activateProgress ? <TinyLoader /> : 'Yes'}
          </button>
        </DialogActions>
      </Dialog>

      <Dialog open={openAddUserModal}>
        <DialogTitle>Create User</DialogTitle>
        <DialogContent>
          <InputField
            type="text"
            name="name"
            placeholder="Name"
            value={addInput.name}
            label="Name"
            onChange={(e) => {
              setAddInput({
                ...addInput,
                name: e.target.value,
              });
            }}
            required
          />

          <InputField
            type="tel"
            maxLength="11"
            max="11"
            min="11"
            name="phoneNumber"
            value={addInput.phoneNumber}
            placeholder="Phone Number e.g 08099999999"
            label="Phone Number"
            onChange={(e) => {
              setAddInput({
                ...addInput,
                phoneNumber: e.target.value,
              });
            }}
            required
          />

          <InputField
            type="tel"
            name="nin"
            placeholder="National ID Card"
            value={addInput.nin}
            label="NIN"
            onChange={(e) => {
              setAddInput({
                ...addInput,
                nin: e.target.value,
              });
            }}
            required
          />

          <InputField
            type="password"
            name="password"
            placeholder="Set Password"
            value={addInput.password}
            label="New Password"
            onChange={(e) => {
              setAddInput({
                ...addInput,
                password: e.target.value,
              });
            }}
            required
          />

          <TextArea
            name="address"
            placeholder="Address"
            label="Address"
            value={addInput.address}
            onChange={(e) => {
              setAddInput({
                ...addInput,
                address: e.target.value,
              });
            }}
            required
          />
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpenAddUserModal(false);
            }}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Cancel
          </button>
          <button
            onClick={!addProgress ? createUser : null}
            className="btn btn-primary btn-round"
          >
            {addProgress ? <TinyLoader /> : 'Create User'}
          </button>
        </DialogActions>
      </Dialog>

      <Dialog open={openEditUserModal}>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <InputField
            name="name"
            placeholder="Name"
            value={editInput.name}
            label="Name"
            onChange={(e) => {
              setEditInput({
                ...editInput,
                name: e.target.value,
              });
            }}
            required
          />

          <InputField
            maxLength="11"
            max="11"
            min="11"
            name="phoneNumber"
            value={editInput.phoneNumber}
            placeholder="Phone Number"
            label="Phone Number"
            onChange={(e) => {
              setEditInput({
                ...editInput,
                phoneNumber: e.target.value,
              });
            }}
            required
          />

          <TextArea
            name="address"
            placeholder="Address"
            label="Address"
            value={editInput.address}
            onChange={(e) => {
              setEditInput({
                ...editInput,
                address: e.target.value,
              });
            }}
            required
          />
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpenEditUserModal(false);
            }}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Cancel
          </button>
          <Button
            isLoading={editProgress}
            onClick={!editProgress ? updateUser : null}
            className="btn btn-primary btn-round"
            value={editProgress ? <TinyLoader /> : 'Save'}
          />
        </DialogActions>
      </Dialog>
      <div className="page-inner">
        <div className="row">
          <div className="col-md-12">
            <div className="header-card">
              <div className="card-header">
                <div className="d-flex align-items-center">
                  <h4 className="card-title">All Users</h4>

                  <SearchField
                    value={searchText}
                    placeholder="Search Users e.g Aliyu or active"
                    onChange={onSearch}
                    onClick={performSearch}
                  />
                  <button className="btn btn-primary btn-round ml-auto">
                    <i className="fa fa-plus"></i>
                    <button
                      className="btn btn-primary btn-round ml-auto"
                      onClick={() => {
                        setOpenAddUserModal(true);
                      }}
                    >
                      Add User
                    </button>
                  </button>
                </div>
              </div>
            </div>

            {user.accountType === UserType.Admin ?
              <div className="filter-container d-flex  align-item-left">
                <InputField
                  type="date"
                  name="from"
                  value={input.from}
                  placeholder="From"
                  label="Start Date"
                  onChange={(e) =>
                    setInput({
                      ...input,
                      from: e.target.value,
                    })
                  }
                  required
                />
                <InputField
                  type="date"
                  name="to"
                  value={input.to}
                  placeholder="To"
                  label="End Date"
                  onChange={(e) =>
                    setInput({
                      ...input,
                      to: e.target.value,
                    })
                  }
                  required
                />
                <OptionField
                  children={location.getCities()}
                  name="city"
                  value={input.city}
                  placeholder="Business area"
                  label="Location"
                  onChange={(e) =>
                    setInput({
                      ...input,
                      city: e.target.value,
                    })
                  }
                  required
                />
                <OptionField
                  children={['All', 'Active', 'InActive', 'Pending']}
                  name="status"
                  value={input.status}
                  placeholder="Status"
                  label="Status"
                  onChange={(e) =>
                    setInput({
                      ...input,
                      status: e.target.value,
                    })
                  }
                  required
                />
                <div className="filter-button text-align-right">
                  <button
                    type="button"
                    onClick={() => {
                      getUsers();
                    }}
                    className="btn default-btn btn-primary btn-large p-2"
                  >
                    Filter
                  </button>
                </div>
              </div>
              : <></>}
            <div className="card">

              <div className="table-responsive table-hover">
                {inProgress ? (
                  <Loader></Loader>
                ) : allUsers.length > 0 ? (
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>ID</th>
                        <th>Business</th>
                        <th>Name</th>
                        <th>Location</th>
                        <th>Telephone</th>
                        <th>Date</th>
                        <th>Payment</th>
                        <th>Status</th>
                        <th>ID</th>
                        <th>Photograph</th>
                        <th style={{ width: '10%' }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allUsers.map((user, index) => {
                        return (
                          <tr key={index}>
                            <td>{++index}</td>
                            <td>{user.membershipId ? user.membershipId : 'N/A'}</td>
                            <td>{titleCase(user.name)}</td>
                            <td>{user.firstName ? titleCase(user.firstName + ' ' + user.lastName) : 'N/A'}</td>
                            <td>
                              {user.lga},{user.state}
                            </td>
                            <td>{user.phoneNumber}</td>
                            <td>{user.createdAt}</td>
                            <td
                              className={
                                user.membershipFeeStatus === 'PAID'
                                  ? 'text-success'
                                  : 'text-danger'
                              }
                            >
                              {user.membershipFeeStatus ? user.membershipFeeStatus : 'N/A'}
                            </td>
                            <td
                              className={
                                user.status === 'ACTIVE'
                                  ? 'text-success'
                                  : 'text-danger'
                              }
                            >
                              {user.status}
                            </td>
                            <td><a href={user.idCard} target="_blank" rel="noreferrer" >View</a></td>
                            <td><a href={user.photograph} target="_blank" rel="noreferrer" >View</a></td>
                            <td>
                              <div className="form-button-action">
                                <button
                                  type="button"
                                  data-toggle="tooltip"
                                  title=""
                                  className="btn btn-primary"
                                  data-original-title="Edit Task"
                                  onClick={() => {
                                    setSelectedUser(user);
                                    setEditInput(user);
                                    setOpenEditUserModal(true);
                                  }}
                                >
                                  <i className="fa fa-edit"></i>
                                </button>

                                <button
                                  type="button"
                                  data-toggle="tooltip"
                                  title=""
                                  className={
                                    user.status === Status.ACTIVE
                                      ? 'btn  btn-danger'
                                      : 'btn  btn-success ml-2'
                                  }
                                  data-original-title="Deactivate"
                                  onClick={(e) => {
                                    setSelectedUser(user);
                                    user.status === Status.ACTIVE
                                      ? setOpenDeactivateModal(true)
                                      : performActivate();
                                  }}
                                >
                                  <i
                                    className={
                                      user.status === Status.ACTIVE
                                        ? '  fa fa-ban'
                                        : ' fa fa-check-square'
                                    }
                                  ></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div className="text-center message-box">
                    <p>
                      {' '}
                      {searchText && searchText.length >= 3
                        ? 'Search not found'
                        : 'No user found'}
                    </p>
                  </div>
                )}
              </div>
            </div>

            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-end">
                <li className="page-item p-3">
                  <a href={downloadURL} download >
                    <button className="btn btn-secondary">
                      <i className="fa fa-download"></i> Download
                    </button>
                  </a>
                </li>
                <li className="page-item">
                  <button className="page-link" onClick={() => changePage(-1)}>
                    Previous
                  </button>
                </li>
                {[...Array(totalPages)].map((e, i) => (
                  <li className="page-item" key={i}>
                    <button
                      className={
                        i === currentPage
                          ? 'page-link btn-primary text-white'
                          : 'page-link'
                      }
                      onClick={() => changePage(i)}
                    >
                      {i}
                    </button>
                  </li>
                ))}
                <li className="page-item">
                  <button className="page-link" onClick={() => changePage(+1)}>
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </OtherPageBody >
  );
};

export default AllUsers;
