import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { titleCase } from "title-case";
import { UserContext } from "../../../Access";
import Avatar from "../../../assets/img/avatar.png";
import { UserType } from "../../../enums/UserType";
import shape1 from "./../../../assets/img/shape/vector-shape11.png";
import shape2 from "./../../../assets/img/shape/shape2.png";
import shape3 from "./../../../assets/img/shape/shape3.png";
import { storage } from "../../../utils/constants/storage";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { UserRole } from "../../../enums/UserRole";
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";

const SideBar = () => {
  const cycle = storage.get("cycle");
  const userSession = useContext(UserContext);
  const [showAccountNumber, setShowAccountNumber] = useState(false);
  const [openThemeModal, setOpenThemeModal] = useState(false);
  const [openCycleModal, setOpenCycleModal] = useState(false);
  const user = userSession.user;

  const [dropdown, setDropdown] = useState({
    profile: false,
    resource: false,
    users: false,
    branch: false,
    top: false,
    tracking: false,
    inventory: false,
    investment: false,
    setup: false,
  });

  const closeAll = () => {
    setDropdown({
      profile: false,
      resource: false,
      users: false,
      top: false,
      branch: false,
      tracking: false,
      inventory: false,
      setup: false,
      investment: false,
    });
  };

  const changeTheme = (theme) => {
    storage.save("theme", theme);
    setOpenThemeModal(false);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const changeCycle = (cycle) => {
    storage.save("cycle", cycle);
    window.location.reload();
  };

  const logout = () => {
    storage.remove("userToken");
    storage.remove("user");
    toast.warn("Logging out...");
    setTimeout(() => {
      window.location = "/user/login";
    }, 500);
  };

  return (
    <div className="sidebar sidebar-style-2">
      <Dialog open={openThemeModal}>
        <DialogTitle>Select Theme</DialogTitle>
        <DialogContent>
          <div className="theme-option">
            <li onClick={() => changeTheme("grayscale(1)")}>Dark</li>
            <li onClick={() => changeTheme("hue-rotate(45deg)")}>Hue</li>
            <li onClick={() => changeTheme("invert(1)")}>Invert</li>
            <li onClick={() => changeTheme("sepia(1)")}>Sepia</li>
            <li onClick={() => changeTheme("saturate(0.5)")}>Saturate</li>
            <li onClick={() => changeTheme("unset")}>Default</li>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpenThemeModal(false);
            }}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Okay
          </button>
        </DialogActions>
      </Dialog>

      <Dialog open={openCycleModal}>
        <DialogTitle>Select Cycle</DialogTitle>
        <DialogContent>
          <div className="theme-option">
            <li
              className={cycle === "DAILY" ? "active" : ""}
              onClick={() => changeCycle("DAILY")}
            >
              Daily
            </li>
            <li
              className={cycle === "MONTHLY" ? "active" : ""}
              onClick={() => changeCycle("MONTHLY")}
            >
              Monthly
            </li>
            <li
              className={cycle === "YEARLY" ? "active" : ""}
              onClick={() => changeCycle("YEARLY")}
            >
              Yearly
            </li>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpenCycleModal(false);
            }}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Okay
          </button>
        </DialogActions>
      </Dialog>

      <div className="sidebar-wrapper scrollbar scrollbar-inner">
        <div className="sidebar-content">
          <div className="user">
            <div
              className={
                "avatar-sm float-left mr-2" + (dropdown.top ? " show" : "")
              }
            >
              <img
                src={user.dp ? user.dp : Avatar}
                onError={(e) => (e.target.src = Avatar)}
                alt="..."
                className="avatar-img rounded-circle"
              />
            </div>
            <div className="info">
              <Link
                to="#"
                data-toggle="collapse"
                aria-expanded={dropdown.profile ? "true" : "false"}
              >
                <span>
                  {titleCase(user.name ? user.name : "Unknown User")}

                  {user.role === UserRole.User &&
                    user.accountType === UserType.Business ? (
                    <small className="text-lightgrey">
                      <strong>{user.business}</strong>
                    </small>
                  ) : (
                    <span className="user-code">
                      {user.code
                        ? showAccountNumber
                          ? user.code
                          : user.code.toString().substring(0, 2) + "****"
                        : "Online"}
                      <i
                        className="fa fa-eye"
                        onClick={() => {
                          setShowAccountNumber(!showAccountNumber);
                        }}
                      ></i>
                    </span>
                  )}
                </span>
              </Link>
              <div className="clearfix"></div>

              <div
                className={"collapse in" + (dropdown.top ? " show" : "")}
                id="collapseExample"
              ></div>
            </div>
          </div>
          <ul className="nav nav-primary">
            <li className="nav-item active">
              <Link to="/dashboard">
                <i className="fas fa-home"></i>
                <p>Dashboard</p>
              </Link>
            </li>
            <li className="nav-section">
              <span className="sidebar-mini-icon">
                <i className="fa fa-ellipsis-h"></i>
              </span>
            </li>

            {user.accountType !== UserType.Individual ? (
              <>
                {user.role === UserRole.User &&
                  user.accountType === UserType.Business ? (
                  <></>
                ) : (
                  <>
                    <li
                      className={"nav-item" + (dropdown.users ? " show" : "")}
                    >
                      <Link
                        to="/user/all"
                        data-toggle="collapse"
                        className={dropdown.users ? "btn-highlight" : ""}
                      >
                        <i className="fas fa-users"></i>
                        <p>Manage Users</p>
                      </Link>
                    </li>
                    <li
                      className={"nav-item" + (dropdown.branch ? " show" : "")}
                    >
                      <Link
                        to="/branch/all"
                        data-toggle="collapse"
                        className={dropdown.branch ? "btn-highlight" : ""}
                      >
                        <i className="fas fa-industry"></i>
                        <p>Manage Branches</p>
                      </Link>
                    </li>
                  </>
                )}
              </>
            ) : (
              <></>
            )}


            {user.accountType !== UserType.Admin ?
              <li
                className={"nav-item" + (dropdown.resource ? " show" : "")}
                onClick={() => {
                  closeAll();
                  setDropdown({
                    ...dropdown,
                    resource: !dropdown.resource,
                  });
                }}
              >
                <a
                  data-toggle="collapse"
                  href="#base"
                  className={dropdown.resource ? "btn-highlight" : ""}
                >
                  <i className="fas fa-server"></i>
                  <p>Manage Resources</p>
                  <span
                    aria-expanded={dropdown.resource}
                    className="caret"
                  ></span>
                </a>
                <div
                  className={"collapse" + (dropdown.resource ? " show" : "")}
                  id="base"
                >
                  <ul className="nav nav-collapse">
                    <Link to="/resource/add">
                      <li>
                        <span className="sub-item">Register Resource</span>
                      </li>
                    </Link>

                    <Link to="/resource/verification">
                      <li>
                        <span className="sub-item">Verify Resource</span>
                      </li>
                    </Link>
                    <Link to="/resource/history">
                      <li>
                        <span className="sub-item">Resource History</span>
                      </li>
                    </Link>
                    <Link to="/resource/all">
                      <li>
                        <span className="sub-item">All Resources</span>
                      </li>
                    </Link>
                  </ul>
                </div>
              </li>
              : <></>}

            {user.accountType !== UserType.Individual ? (
              <>
                <li className="nav-item">
                  <Link to="/inventory/analytics" data-toggle="collapse">
                    <i className="fas fa-clone"></i>
                    <p>General Analytics</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/inventory/transactions" data-toggle="collapse">
                    <i className="fas fa-list"></i>
                    <p>Manage Transactions</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/expenses" data-toggle="collapse">
                    <i className="fas fa-briefcase"></i>
                    <p>Manage Expenses</p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to="/debt" data-toggle="collapse">
                    <i className="fas fa-tag"></i>
                    <p>Manage Debts</p>
                  </Link>
                </li>
              </>
            ) : (
              <></>
            )}

            {user.accountType === UserType.Individual ? (
              <li className="nav-item">
                <Link to="/tracking/requests" data-toggle="collapse">
                  <i className="fas fa-map-marker"></i>
                  <p>Manage Tracking</p>
                </Link>
              </li>
            ) : (
              <></>
            )}
            <li
              className={"nav-item" + (dropdown.profile ? " show" : "")}
              onClick={() => {
                closeAll();
                setDropdown({
                  ...dropdown,
                  profile: !dropdown.profile,
                });
              }}
            >
              <a
                data-toggle="collapse"
                href="#base"
                className={dropdown.profile ? "btn-highlight" : ""}
              >
                <i className="fas fa-user"></i>
                <p>Manage Account</p>
                <span aria-expanded={dropdown.profile} className="caret"></span>
              </a>
              <div
                className={"collapse" + (dropdown.profile ? " show" : "")}
                id="base"
              >
                <ul className="nav nav-collapse">
                  <Link to="/user/profile">
                    <li>
                      <span className="sub-item">View Profile</span>
                    </li>
                  </Link>

                  <Link
                    to="#"
                    onClick={() => {
                      storage.save("locked", "yes");
                      setTimeout(() => {
                        window.location.reload();
                      }, 500);
                    }}
                  >
                    <li>
                      <span className="sub-item">Lock Screen</span>
                    </li>
                  </Link>

                  <Link to="/user/reset-password">
                    <li>
                      <span className="sub-item">Reset Password</span>
                    </li>
                  </Link>

                  <Link to="#">
                    <li>
                      <CopyToClipboard
                        text={
                          "https://ammon.phopis.com/user/register/business?referee=?" +
                          user.code
                        }
                        onCopy={() => {
                          toast.success("Referral Link Copied...");
                        }}
                      >
                        <div className="sub-item">Copy Referral Link</div>
                      </CopyToClipboard>
                    </li>
                  </Link>

                  <Link to="#" onClick={logout}>
                    <li>
                      <span className="sub-item">Logout</span>
                    </li>
                  </Link>
                </ul>
              </div>
            </li>


            {user.accountType !== UserType.Admin ?
              <li className="nav-item">
                <a href="https://www.clubkash.com/AMMON/Login.asp?NewPage=My" data-toggle="collapse">
                  <i className="fas fa-globe"></i>
                  <p>Airtime and Data</p>
                </a>
              </li>
              : <></>}

            <li className="nav-item">
              <a href="https://chat.whatsapp.com/HuTaP0ZWNhPHUnqgUfQLaV" data-toggle="collapse">
                <i className="fas fa-envelope"></i>
                <p>Join us</p>
              </a>
            </li>

            {user.accountType !== UserType.Individual ? (
              <li
                className={"nav-item" + (dropdown.setup ? " show" : "")}
                onClick={() => {
                  closeAll();
                  setDropdown({
                    ...dropdown,
                    setup: !dropdown.setup,
                  });
                }}
              >
                <a
                  data-toggle="collapse"
                  href="#base"
                  className={dropdown.setup ? "btn-highlight" : ""}
                >
                  <i className="fas fa-cogs"></i>
                  <p>Settings</p>
                  <span aria-expanded={dropdown.setup} className="caret"></span>
                </a>
                <div
                  className={"collapse" + (dropdown.setup ? " show" : "")}
                  id="base"
                >
                  <ul className="nav nav-collapse">
                    <Link
                      to="#"
                      onClick={() => {
                        setOpenThemeModal(true);
                      }}
                    >
                      <li>
                        <span className="sub-item">Change theme</span>
                      </li>
                    </Link>

                    <Link
                      to="#"
                      onClick={() => {
                        setOpenCycleModal(true);
                      }}
                    >
                      <li>
                        <span className="sub-item">Change Cycle</span>
                      </li>
                    </Link>
                  </ul>
                </div>
              </li>
            ) : (
              <></>
            )}
          </ul>
          <div className="shape-img1">
            <img src={shape1} alt="images" />
          </div>
          <div className="shape-img2">
            <img src={shape2} alt="images" />
          </div>
          <div className="shape-img3">
            <img src={shape3} alt="images" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
