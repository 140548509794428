import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from './components/button/Button';
import { auth } from '../../service/auth.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { messages } from '../../utils/constants/messages';
import { storage } from '../../utils/constants/storage';
import shape1 from './../../assets/img/shape/vector-shape11.png';
import shape2 from './../../assets/img/shape/shape2.png';
import shape3 from './../../assets/img/shape/shape3.png';
import SignNavBar from './components/SignNavBar';
import UsernameInputField from './components/inputs/UsernameInputField';
import PasswordInputField from './components/inputs/PasswordInputField';

const Login = () => {
  const [input, setInput] = useState({
    username: '',
    password: '',
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const { username, password } = input;

  const login = () => {
    if (input.username === null || input.username.length <10)
      toast.error(messages.usernameMessage);
    else if (input.password === null || input.password.length < 3)
      toast.error(messages.usernameLengthMessage);

    if (
      input.username &&
      input.password &&
      input.username.length >=10 &&
      input.password.length >= 3
    ) {
      const request = {
        username: input.username,
        password: input.password,
      };

      setIsProcessing(true);
      auth
        .userLogin(request)
        .then((result) => {
          setIsProcessing(false);
          if (result.data.success) {
            toast.success(result.data.message);
            let userDetails = result.data.data.info;
            let userToken = result.data.data.access_token;

            storage.save('locked', 'no');
            storage.save('userToken', userToken);
            storage.save('user', JSON.stringify(userDetails));
            setTimeout(() => {
              window.location.href = '/dashboard';
            }, 500);
          } else {
            toast.error(messages.invalidCredentials);
          }
        })
        .catch((e) => {
          setIsProcessing(false);
          if (e.response.data) {
            toast.error(e.response.data.message);
          } else {
            toast.error('Oops! Something went wrong');
          }
        });
    }
  };
  return (
    <div className="wrapper sign-body">
      <SignNavBar />
      <ToastContainer />
      <div className="page-title-area">
        <div className="container">
          <div className="page-title-content">
            <h2>Welcome to AMMON</h2>
            <ul>
              <li>Don't have an Account?</li>
              &nbsp;
              <li>
                <i className="fa fa-pencil-square"></i>
                <Link to="/user/register/business/">Signup Now</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="shape-img1">
          <img src={shape1} alt="images" />
        </div>
        <div className="shape-img2">
          <img src={shape2} alt="images" />
        </div>
        <div className="shape-img3">
          <img src={shape3} alt="images" />
        </div>
      </div>
      <div className="sign-container">
        <div className="rounded">
          <div className="card-body">
            <div className="">
              <UsernameInputField
                type="text"
                value={username}
                placeholder="Membership ID or Phone Number"
                label="Username"
                name="username"
                onChange={(e) =>
                  setInput({
                    ...input,
                    username: e.target.value,
                  })
                }
                required
              />
            </div>

            <div className="">
              <PasswordInputField
                type="password"
                value={password}
                placeholder="Password"
                label="Password"
                name="password"
                onChange={(e) =>
                  setInput({
                    ...input,
                    password: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="my-2">
              <div className="float-right text-sm">
                <Link to="/user/reset-password">Forget Password ?</Link>
              </div>
            </div>

            <div className="button mt-5">
              <Button
                isLoading={isProcessing}
                value='Login'
                type="button"
                name="button"
                onClick={() => (!isProcessing ? login() : null)}
                contain={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
