import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { storage } from '../../utils/constants/storage';
import SignNavBar from './components/SignNavBar';

const RegSuccess = () => {

  storage.save('locked', 'no');

  return (
    <div className="wrapper sign-body">
      <SignNavBar />
      <ToastContainer />
      <div className="page-title-area">
        <div className="container">
          <div className="page-title-content">
            <h1>Your Registration is Successful</h1>
            <p>We will review and get back to you in 48 hours</p>

            <Link to="/user/login" className="btn default-btn btn-primary">Okay, Thanks</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegSuccess;
