import React, { useState } from "react";
import { useEffect } from "react";
import OtherPageBody from "./OtherPageBody";
import { inventoryService } from "../../service/inventory.service";
import { ToastContainer, toast } from "react-toastify";
import Loader from "./components/loaders/Loader";
import InputField from "./components/inputs/InputField";
import { AttachMoney } from "@mui/icons-material";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import toMoney from "number-to-money";
import { useContext } from "react";
import { UserContext } from "../../Access";
import { UserRole } from "../../enums/UserRole";
import { debtService } from "../../service/debt.service";
import { Link } from "react-router-dom";
import { expenseService } from "../../service/expense.service";
import { titleCase } from "title-case";
import { storage } from "../../utils/constants/storage";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { format } from "timeago.js";

const InventoryAnalytics = () => {
  const userSession = useContext(UserContext);
  const user = userSession.user;

  const [cycle, setCycle] = useState(storage.get('cycle'))
  const [inventoryAnalytics, setInventoryAnalytics] = useState([]);
  const [analyticsProgress, setAnalyticsProgress] = useState(false);
  const [debtAnalyticsProgress, setDebtAnalyticsProgress] = useState(false);
  const [expenseAnalyticsProgress, setExpenseAnalyticsProgress] = useState(false);
  const [transactionHistoryProgress, setTransactionHistoryProgress] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const { width } = useWindowDimensions();
  const [debtAnalytics, setDebtAnalytics] = useState({});
  const [expenseAnalytics, setExpenseAnalytics] = useState({});
  const [input, setInput] = useState({ from: "", to: "" });

  const getTransactionAnalytics = () => {
    const request = {
      from: input.from,
      to: input.to,
    };
    setAnalyticsProgress(true);
    inventoryService
      .getTransactionAnalytics(request)
      .then((response) => {
        setAnalyticsProgress(false)
        if (response.data.success) {
          setInventoryAnalytics(response.data.data);
        }
      })
      .catch((err) => setAnalyticsProgress(false));
  };


  const getDebtAnalytics = () => {
    setDebtAnalyticsProgress(true);
    debtService
      .getDebtAnalytics()
      .then((response) => {
        setDebtAnalyticsProgress(false);
        console.log(response.data);
        if (response.data.success) {
          setDebtAnalytics(response.data.data);
        }
      })
      .catch((err) => setDebtAnalyticsProgress(false));
  };

  const getExpenseAnalytics = () => {
    setExpenseAnalyticsProgress(true);
    expenseService
      .getExpenseAnalytics()
      .then((response) => {
        setExpenseAnalyticsProgress(false);
        console.log(response.data);
        if (response.data.success) {
          setExpenseAnalytics(response.data.data);
        }
      })
      .catch((err) => setExpenseAnalyticsProgress(false));
  };

  const getAllTransactions = () => {
    const request = {
      from: input.from,
      to: input.to,
    };
    setTransactionHistoryProgress(true);
    inventoryService.getAllTransactions(request)
      .then((result) => {
        setTransactionHistoryProgress(false);
        if (result.data.success) {
          setTransactions(result.data.data.page);
        }
      })
      .catch((e) => {
        setTransactions([]);
        setTransactionHistoryProgress(false);
      });
  };

  useEffect(() => {
    getTransactionAnalytics();
    getDebtAnalytics();
    getExpenseAnalytics();
    getAllTransactions();
  }, []);

  return (
    <OtherPageBody>
      <ToastContainer />
      <div className="container-fluid py-5 px-4">
        <div className="header-card mt--3">
          <div className="card-header">
            <h4 className="text-left">
              <i className="fa fa-pencil"></i> Report & Analytics
            </h4>
            <small>
              <span>{cycle ? titleCase(cycle.toLowerCase()) : 'Daily'} report</span>, change the start and end date below to filter
              previous transactions
            </small>
          </div>
        </div>
        <div className="justify-content-left flex-fill">
          <div className="row inventory-summary-cards">
            <div className="col-xl-3 col-lg-3 col-md-3 col-6">
              <div className="card">
                <Link to="/inventory/transactions">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="">
                        <div className="card-title font-weight-bold">
                          Total
                        </div>
                        <div className="card-text">
                          {analyticsProgress ? (
                            <Loader />
                          ) : (
                            <>
                              ₦
                              {inventoryAnalytics.totalAmount
                                ? toMoney(inventoryAnalytics.totalAmount)
                                : "0.00"}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="ml-auto">
                        <AttachMoney style={{ fontSize: "50px" }} />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 col-6">
              <div className="card">
                <div className="card-body ">
                  <div className="d-flex">
                    <div className="">
                      <div className="card-title font-weight-bold">Revenue</div>
                      <div className="card-text">
                        {analyticsProgress ? (
                          <Loader />
                        ) : (
                          <>
                            ₦
                            {user.role !== UserRole.User
                              ? inventoryAnalytics.totalRevenue
                                ? toMoney(inventoryAnalytics.totalRevenue)
                                : "0.00"
                              : "****"}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="ml-auto">
                      <ReceiptOutlinedIcon style={{ fontSize: "40px" }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-3 col-6">
              <div className="card">
                <Link to="/debt">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="">
                        <div className="card-title font-weight-bold">Debt</div>
                        <div className="card-text">
                          {debtAnalyticsProgress ? (
                            <Loader />
                          ) : (
                            <>
                              ₦
                              {debtAnalytics.totalAmount
                                ? toMoney(
                                  debtAnalytics.totalAmount -
                                  debtAnalytics.totalCleared
                                )
                                : "0"}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="ml-auto">
                        <AttachMoney style={{ fontSize: "40px" }} />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-3 col-6">
              <div className="card">
                <div className="card-body">
                  <Link to="/expenses">
                    <div className="d-flex">
                      <div className="">
                        <div className="card-title font-weight-bold">
                          Expenses
                        </div>
                        <div className="card-text">
                          {expenseAnalyticsProgress ? (
                            <Loader />
                          ) : (
                            <>
                              ₦
                              {expenseAnalytics.totalAmount
                                ? toMoney(expenseAnalytics.totalAmount)
                                : "0"}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="ml-auto">
                        <AttachMoney style={{ fontSize: "40px" }} />
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 mb-5">
          <h5 className="font-weight-bold">Transaction History</h5>
          <div className="filter-container d-flex  align-item-left">
            <InputField
              type="date"
              name="from"
              value={input.from}
              placeholder="From"
              label="Start Date"
              onChange={(e) =>
                setInput({
                  ...input,
                  from: e.target.value,
                })
              }
              required
            />
            <InputField
              type="date"
              name="to"
              value={input.to}
              placeholder="To"
              label="End Date"
              onChange={(e) =>
                setInput({
                  ...input,
                  to: e.target.value,
                })
              }
              required
            />
            <div className="filter-button text-align-right">
              <button
                type="button"
                onClick={() => {
                  getAllTransactions();
                }}
                className="btn default-btn btn-primary btn-large p-2"
              >
                Filter
              </button>
            </div>
          </div>
        </div>
        <div className="row data-wrapper">
          {transactionHistoryProgress ? (
            <div className="row card">
              <Loader></Loader>
            </div>
          ) : transactions.length > 0 ? (
            width > 800 ? (
              <div className="row card">
                <div className="card-body">
                  <table
                    id="add-row"
                    className="display table table-striped table-hover"
                  >
                    <thead>
                      <tr>
                        <th>S/n</th>
                        <th>Customer Name</th>
                        <th>Phone Number</th>
                        <th>Transaction Type</th>
                        <th>Amount</th>
                        <th>Revenue</th>
                        <th>Date</th>
                      </tr>
                    </thead>

                    <tbody>
                      {transactions.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{++index}</td>
                            <td>{item.customerName}</td>
                            <td>{item.customerPhoneNumber}</td>
                            <td>{item.transactionType}</td>
                            <td>₦{toMoney(item.amount)}</td>
                            <td>₦{user.role === UserRole.User ? '****' : item.revenue ? toMoney(item.revenue) : '0'}</td>
                            <td>{item.createdAt}</td>

                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              transactions.map((item, index) => {
                return (
                  <div
                    className="list-card-item col-xl-2 col-lg-4 col-md-4 col-12"
                    key={index}
                  >
                    <div className="image-flip">
                      <div className="mainflip flip-0">
                        <div className="frontside">
                          <div className="card">
                            <div className="invoice-ribbon">
                              <div className="ribbon-inner  bg-primary">
                                Paid
                              </div>
                            </div>
                            <div className="card-body text-left">
                              <h4 className="card-title text-primary">
                                {item.name}
                              </h4>
                              <ul className="card-text">
                                <li>
                                  <h2><strong>{item.transactionType}</strong></h2>
                                </li>
                                <li>
                                  <strong>{item.customerName}</strong>
                                </li>
                                <li>
                                  Total Amount:{' '}
                                  <span className="text-danger">
                                    ₦{toMoney(item.amount)}
                                  </span>
                                </li>
                                <li>
                                  Total Revenue:{' '}
                                  <span className="text-success">
                                    ₦{user.role === UserRole.User ? '****' : item.revenue ? toMoney(item.revenue) : '0'}
                                  </span>
                                </li>
                                <li>
                                  Discount:{' '}
                                  <span className="text-danger">
                                    {' '}
                                    ₦{item.discount ? item.discount : 0}
                                  </span>
                                </li>
                                <li>Created :<span className="text-primary">{item.createdAt}</span></li>
                                <li>
                                  Status :{' '}
                                  <span className="text-success">
                                    Successful
                                  </span>
                                </li>
                              </ul>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )
          ) : (
            <div className="row card">
              <div className="text-center message-box">
                <p>No Transaction found</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </OtherPageBody >
  );
};

export default InventoryAnalytics;
