import { util } from "../utils/constants/constants";
import { http } from "../utils/constants/http";
import { storage } from "../utils/constants/storage";

let inventoryService = {
  getAllTransactions: async (filter) => {
    let url = `/sale/list`;
    if (filter && filter.from && filter.to) {
      url += `?from=${filter.from}&to=${filter.to}`;
    } else {
      const currentCycle = util.decodeCycle(storage.get('cycle'))
      url += `?from=${currentCycle.from}&to=${currentCycle.to}`;
    }
    if (filter && filter.transactionType) {
      url += `&type=${filter.transactionType}`
    }

    return await http.get(`${util.endPoint}${url}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },

  downloadTransactions: async (filter) => {
    let url = `/sale/list/download`;
    if (filter && filter.from && filter.to) {
      url += `?from=${filter.from}&to=${filter.to}`;
    } else {
      const currentCycle = util.decodeCycle(storage.get('cycle'))
      url += `?from=${currentCycle.from}&to=${currentCycle.to}`;
    }
    if (filter && filter.transactionType) {
      url += `&type=${filter.transactionType}`
    }
    return await http.get(`${util.endPoint}${url}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  searchTransactions: async (filter, currentPage, searchText) => {
    let url = `/sale/search?page=${currentPage}&q=${searchText}`;
    if (filter && filter.from && filter.to) {
      url += `&from=${filter.from}&to=${filter.to}`;
    } else {
      const currentCycle = util.decodeCycle(storage.get('cycle'))
      url += `&from=${currentCycle.from}&to=${currentCycle.to}`;
    }
    if (filter && filter.transactionType) {
      url += `&type=${filter.transactionType}`
    }

    return await http.get(`${util.endPoint}${url}`, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  createTransaction: async (body) => {
    return await http.post(`${util.endPoint}/sale/transaction`, body, {
      headers: util.getAuthorizedHeaders(),
    });
  },
  getTransactionAnalytics: async (filter) => {
    let url = `${util.endPoint}/report/transaction/analytics`
    if (filter && filter.from && filter.to) {
      url += `?from=${filter.from}&to=${filter.to}`;
    } else {
      const currentCycle = util.decodeCycle(storage.get('cycle'))
      url += `?from=${currentCycle.from}&to=${currentCycle.to}`;
    }
    return await http.get(url, {
      headers: util.getAuthorizedHeaders(),
    });
  }
}

export { inventoryService };