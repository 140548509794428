import axios from "axios";
import { storage } from "./storage";

function HttpRequests() {
  this.setLastUsed = () => {
    storage.save('lastUsed', new Date().toISOString())
  }
  this.post = async (url, data, headers) => {
    this.setLastUsed();
    return await axios.post(url, data, headers);
  };
  this.put = async (url, data, headers) => {
    this.setLastUsed();
    return await axios.put(url, data, headers);
  };
  this.delete = async (url, data, headers) => {
    this.setLastUsed();
    return await axios.delete(url, data, headers);
  };
  this.get = async (url, data, headers) => {
    this.setLastUsed();
    return await axios.get(url, data, headers);
  };
}

const http = new HttpRequests();
export { http };
