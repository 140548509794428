import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from './components/button/Button';
import InputField from './components/inputs/InputField';
import { auth } from '../../service/auth.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { messages } from '../../utils/constants/messages';
import { UserType } from '../../enums/UserType';
import OptionField from './components/select/OptionField';
import { location } from '../../utils/constants/location';
import shape1 from './../../assets/img/shape/vector-shape11.png';
import shape2 from './../../assets/img/shape/shape2.png';
import shape3 from './../../assets/img/shape/shape3.png';
import SignNavBar from './components/SignNavBar';
import { fileService } from '../../service/file.service';
import { closePaymentModal, useFlutterwave } from 'flutterwave-react-v3';
import { util } from '../../utils/constants/constants';
import { storage } from '../../utils/constants/storage';

const SignUpBusiness = () => {
  const [input, setInput] = useState({
    firstName: '',
    lastName: '',
    name: '',
    membershipId: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    state: '',
    lga: '',
    address: '',
    photograph: '',
    idCard: '',
    gender: '',
    religion: ''
  });
  const [request, setRequest] = useState({});
  const [step, setStep] = useState({
    step1: true,
    step2: false,
  });
  const [photographFile, setPhotographFile] = useState();
  const [idCardFile, setIdCardFile] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const [cities] = useState(location.getCities());
  const [area, setAreas] = useState(['Select area...']);
  const [publicKey, setPublicKey] = useState(
    'FLWPUBK-df12d779e5753b10ab7781ff187230c8-X',
  );
  const config = {
    public_key: publicKey,
    tx_ref: `mem${util.getRandomString()}`,
    amount: 10000 + Number(util.paymentCharges),
    currency: 'NGN',
    customer: {
      email: 'support@ammon.com',
      phone_number: request.phoneNumber,
      name: request.name,
    },
    customizations: {
      title: 'Membership Registration',
      description: 'Payment for membership registration',
      logo: 'https://ammon.phopis.com/icon.png',
    },
  };
  const handleFlutterPayment = useFlutterwave(config);

  const validateStep1 = () => {
    if (input.firstName === '' || input.firstName.length < 3) toast.error('Provide valid first name');
    else if (input.lastName === '' || input.lastName.length < 3)
      toast.error('Provide valid last name');
    else if (input.name === '' || input.name.length < 3) toast.error('Provide valid business name');
    else if (input.phoneNumber === '' || input.phoneNumber.length !== 11)
      toast.error('Provide valid Phone number');
    else if (input.state === 'Select location...')
      toast.error('Select business location');
    else if (input.lga === '' || input.lga === 'Select area...')
      toast.error('Select location area');
    else if (input.address === '') toast.error('Enter business address');
    else {

      let valid = true;
      if (input.membershipId !== '' && input.membershipId.length > 0) {
        if (!util.validMembershipId(input.membershipId)) {
          valid = false;
        }
      }

      if (valid) {
        const userRequest = {
          firstName: input.firstName,
          lastName: input.lastName,
          name: input.name,
          membershipId: input.membershipId,
          accountType: UserType.Business,
          phoneNumber: input.phoneNumber,
          alias: input.name.replaceAll(' ', '').toLowerCase(),
          state: input.state,
          lga: input.lga,
          address: input.address,
        };
        setRequest(userRequest)
        setStep({
          step1: false,
          step2: true,
        })
      } else {
        toast.error("Invalid Membership ID")
      }
    }
  };

  const completeRegistration = async () => {
    if (input.idCard === '' || input.idCard.length < 3)
      toast.error('Select valid Id Card');
    else if (input.photograph === '' || input.photograph.length < 3)
      toast.error('Select valid photograph');
    else if (input.gender === '' || input.gender === 'Select gender...')
      toast.error('Select your gender');
    else if (input.religion === '' || input.religion === 'Select religion...')
      toast.error('Select your religion');
    else if (input.password.length < 6)
      toast.error(messages.passwordLengthMessage);
    else if (input.confirmPassword !== input.password)
      toast.error(messages.passwordMisMatch);
    else {
      setIsProcessing(true);
      const userRequest = {
        ...request,
        gender: input.gender,
        religion: input.religion,
        password: input.password,
      };
      const filesData = new FormData();
      filesData.append('files[]', idCardFile);
      filesData.append('files[]', photographFile);
      const fileResponse = await fileService.uploadSilentFiles(filesData);

      if (fileResponse.data.success) {
        const fileURLs = fileResponse.data.data;
        userRequest.idCard = fileURLs[0].url;
        userRequest.photograph = fileURLs[1].url;
        setRequest(userRequest)

        auth.registerUser(userRequest)
          .then((result) => {
            console.log(result)
            if (result.data.success) {
              console.log(util.validMembershipId(userRequest.membershipId))
              if (util.validMembershipId()) {
                setIsProcessing(false);
                toast.success('Submitted successfully');
                storage.save('username', request.phoneNumber);
                setTimeout(() => {
                  window.location = '/user/registration-success';
                }, 500);
              } else {
                makePayment();
              }
            } else {
              toast.error(result.data.message)
            }
          })
          .catch((e) => {
            console.log(e)
            setIsProcessing(false);
            toast.error(e.response ? e.response.data.message : 'Unable to create your account');
          });
      }
      else {
        toast.error(fileResponse.data.message)
      }
    }
  }


  const makePayment = () => {
    setIsProcessing(true);
    handleFlutterPayment({
      callback: (response) => {
        setIsProcessing(false);

        if (response.status && response.status === 'completed') {
          request.paymentRef = response.tx_ref;
          request.transactionId = response.transaction_id;
          toast.success('Submitted successfully');
          storage.save('username', request.phoneNumber);
          setTimeout(() => {
            window.location = '/user/registration-success';
          }, 500);
        } else {
          toast.error(response.status);
        }
        closePaymentModal();
      },
      onClose: () => { },
    });
  }

  useEffect(() => {
    storage.remove('locked')
  }, [])

  return (
    <div className="wrapper sign-body">
      <SignNavBar />
      <ToastContainer />
      <div className="page-title-area">
        <div className="container">
          <div className="page-title-content">
            <h2>Signup to AMMON</h2>
            <ul>
              <li>Already have an account?</li>
              &nbsp;
              <li>
                <i className="fa fa-pencil-square"></i>
                <Link to="/user/login">Login</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="shape-img1">
          <img src={shape1} alt="images" />
        </div>
        <div className="shape-img2">
          <img src={shape2} alt="images" />
        </div>
        <div className="shape-img3">
          <img src={shape3} alt="images" />
        </div>
      </div>
      <div className="sign-container">
        <div className="rounded">
          <div className="card-body">
            {step.step1 ?
              <>
                <div className="row">

                  <div className="col-md-6">
                    <InputField
                      type="text"
                      value={input.firstName}
                      placeholder="First Name"
                      label="First name"
                      option="**"
                      name="firstName"
                      onChange={(e) => {
                        setInput({
                          ...input,
                          firstName: e.target.value,
                        });
                      }
                      }
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <InputField
                      type="text"
                      value={input.lastName}
                      placeholder="Last Name"
                      label="Last Name"
                      name="lastName"
                      onChange={(e) => {
                        setInput({
                          ...input,
                          lastName: e.target.value
                        })
                      }}
                    />
                  </div>


                  <div className="col-md-6">
                    <InputField
                      type="text"
                      value={input.name}
                      placeholder="Enter your Business name"
                      label="Business Name"
                      option="**"
                      name="name"
                      onChange={(e) => {
                        setInput({
                          ...input,
                          name: e.target.value,
                        });
                      }
                      }
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <InputField
                      type="text"
                      value={input.membershipId}
                      placeholder="Your Membership Id e.g AMMON/ST/YR/SN"
                      label="Already a member?"
                      maxLength="20"
                      name="membershipId"
                      onChange={(e) => {
                        setInput({
                          ...input,
                          membershipId: e.target.value
                        })
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      type="tel"
                      value={input.phoneNumber}
                      placeholder="Enter your Business Phone Number"
                      label="Phone Number"
                      maxLength="11"
                      option="**"
                      name="phoneNumber"
                      onChange={(e) =>
                        setInput({
                          ...input,
                          phoneNumber: e.target.value,
                        })
                      }
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <OptionField
                      value={input.state}
                      children={cities}
                      placeholder="Where is your Business Located?"
                      label="Business Location"
                      name="state"
                      onChange={(e) => {
                        setAreas(location.getArea(e.target.value));
                        setInput({
                          ...input,
                          state: e.target.value,
                        });
                      }}
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <OptionField
                      value={input.lga}
                      children={area}
                      placeholder="Local Government"
                      label="Location Area"
                      name="lga"
                      onChange={(e) =>
                        setInput({
                          ...input,
                          lga: e.target.value,
                        })
                      }
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <InputField
                      type="text"
                      value={input.address}
                      placeholder="Enter Street Address"
                      label="Business Address"
                      name="address"
                      onChange={(e) =>
                        setInput({
                          ...input,
                          address: e.target.value,
                        })
                      }
                      required
                    />
                  </div>

                </div>

                <Button
                  isLoading={isProcessing}
                  type="button"
                  value={
                    isProcessing ? messages.processingMessage : 'Continue'
                  }
                  name="button"
                  onClick={() => (!isProcessing ? validateStep1() : null)}
                  contain={true}
                />
              </>
              : <></>}

            {step.step2 ?
              <>
                <div className="row">
                  <div className="col-md-6">
                    <InputField
                      type="file"
                      value={input.idCard}
                      placeholder="Choose ID Card"
                      label="Upload Valid ID Card"
                      name="idCard"
                      onChange={(e) => {
                        console.log(e.target.files)
                        setIdCardFile(e.target.files[0])
                        setInput({
                          ...input,
                          idCard: e.target.value,
                        })
                      }
                      }
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <InputField
                      type="file"
                      value={input.photograph}
                      placeholder="Upload photograph"
                      label="Upload photograph"
                      name="photograph"
                      onChange={(e) => {
                        console.log(e.target.files)
                        setPhotographFile(e.target.files[0])
                        setInput({
                          ...input,
                          photograph: e.target.value,
                        })
                      }
                      }
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <OptionField
                      children={['Select gender...', 'Male', 'Female']}
                      value={input.gender}
                      placeholder="Gender"
                      label="Whats your Gender"
                      name="gender"
                      onChange={(e) =>
                        setInput({
                          ...input,
                          gender: e.target.value,
                        })
                      }
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <OptionField
                      children={['Select religion...', 'Islam', 'Christianity', 'Other']}
                      value={input.religion}
                      placeholder="Religion"
                      label="Whats your Religion"
                      name="religion"
                      onChange={(e) =>
                        setInput({
                          ...input,
                          religion: e.target.value,
                        })
                      }
                      required
                    />
                  </div>


                  <div className="col-md-6">
                    <InputField
                      type="password"
                      value={input.password}
                      placeholder="Enter Password"
                      label="Password"
                      option="*"
                      name="password"
                      onChange={(e) =>
                        setInput({
                          ...input,
                          password: e.target.value,
                        })
                      }
                      required
                    ></InputField>
                  </div>
                  <div className="col-md-6">
                    <InputField
                      type="password"
                      value={input.confirmPassword}
                      placeholder="Enter Confirm Password"
                      label="Confirm Password"
                      option="*"
                      name="confirmPassword"
                      onChange={(e) =>
                        setInput({
                          ...input,
                          confirmPassword: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                </div>
                <Button
                  isLoading={isProcessing}
                  type="button"
                  value={
                    isProcessing ? messages.processingMessage : 'Complete Registration'
                  }
                  name="button"
                  onClick={() => (!isProcessing ? completeRegistration() : null)}
                  contain={true} />
              </>
              : <></>}
          </div>
        </div>
        <div className="my-2 mb-5">
          <div className="text-center">
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpBusiness;
