import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from './components/button/Button';
import InputField from './components/inputs/InputField';
import { auth } from '../../service/auth.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { messages } from '../../utils/constants/messages';
import { UserType } from '../../enums/UserType';
import { storage } from '../../utils/constants/storage';
import shape1 from './../../assets/img/shape/vector-shape11.png';
import shape2 from './../../assets/img/shape/shape2.png';
import shape3 from './../../assets/img/shape/shape3.png';
import SignNavBar from './components/SignNavBar';

const SignUp = () => {
  const [input, setInput] = useState({
    name: '',
    phoneNumber: '',
    nin: '',
    password: '',
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  const create = () => {
    if (input.name.length < 3) toast.error('Provide valid full name');
    if (input.phoneNumber.length < 11) toast.error(messages.phoneLengthMessage);
    else if (input.nin.length < 11) toast.error(messages.ninLengthMessage);
    else if (input.password.length < 6)
      toast.error(messages.passwordLengthMessage);
    else if (input.confirmPassword !== input.password)
      toast.error(messages.passwordMisMatch);

    if (
      input.name.length >= 3 &&
      input.phoneNumber.length >= 11 &&
      input.nin.length >= 11 &&
      input.password.length >= 6 &&
      input.confirmPassword === input.password
    ) {
      const request = {
        name: input.name,
        phoneNumber: input.phoneNumber,
        nin: input.nin,
        accountType: UserType.Individual,
        password: input.password,
      };
      if (input.address) request.address = input.address;

      setIsProcessing(true);
      auth
        .registerUser(request)
        .then((result) => {
          setIsProcessing(false);
          if (result.data.success) {
            toast.success(result.data.message);
            storage.save('username', result.data.data.phoneNumber);
            setTimeout(() => {
              navigate('/user/verify-account');
            }, 500);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          setIsProcessing(false);
          toast.error(e.response.data.message);
        });
    } else {
      console.log('condition not mate');
    }
  };

  return (
    <div className="wrapper sign-body">
      <SignNavBar />
      <ToastContainer />
      <div className="page-title-area">
        <div className="container">
          <div className="page-title-content">
            <h2>Apply for AMMON Membership</h2>
            <ul>
              <li>Already a member?</li>
              &nbsp;
              <li>
                <i className="fa fa-pencil-square"></i>
                <Link to="/user/login">Login</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="shape-img1">
          <img src={shape1} alt="images" />
        </div>
        <div className="shape-img2">
          <img src={shape2} alt="images" />
        </div>
        <div className="shape-img3">
          <img src={shape3} alt="images" />
        </div>
      </div>
      <div className="sign-container">
        <div className="rounded">
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <InputField
                  type="text"
                  value={input.name}
                  placeholder="Enter your fullname"
                  label="Name"
                  name="name"
                  onChange={(e) =>
                    setInput({
                      ...input,
                      name: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div className="col-md-6">
                <InputField
                  type="number"
                  value={input.nin}
                  placeholder="Enter your NIN"
                  label="National ID card"
                  maxLength="11"
                  option="**"
                  name="nin"
                  onChange={(e) =>
                    setInput({
                      ...input,
                      nin: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div className="col-md-6">
                <InputField
                  type="tel"
                  value={input.phoneNumber}
                  placeholder="Enter your Phone Number"
                  label="Phone Number"
                  maxLength="11"
                  option="**"
                  name="phone"
                  onChange={(e) =>
                    setInput({
                      ...input,
                      phoneNumber: e.target.value,
                    })
                  }
                  required
                />
              </div>

              <div className="col-md-6">
                <InputField
                  type="password"
                  value={input.password}
                  placeholder="Enter Password"
                  label="Password"
                  option="*"
                  name="password"
                  onChange={(e) =>
                    setInput({
                      ...input,
                      password: e.target.value,
                    })
                  }
                  required
                ></InputField>
              </div>
              <div className="col-md-6">
                <InputField
                  type="password"
                  value={input.confirmPassword}
                  placeholder="Enter Confirm Password"
                  label="Confirm Password"
                  option="*"
                  name="confirmPassword"
                  onChange={(e) =>
                    setInput({
                      ...input,
                      confirmPassword: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>

            <div className="button mt-2 mr-2">
              <Button
                isLoading={isProcessing}
                type="button"
                value={
                  isProcessing ? messages.processingMessage : 'Continue'
                }
                name="button"
                onClick={() => (!isProcessing ? create() : null)}
                contain={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
