import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { format } from 'timeago.js';
import OtherPageBody from './OtherPageBody';
import Loader from './components/loaders/Loader';
import SearchField from './components/inputs/SearchField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from 'react-router-dom';
import toMoney from 'number-to-money';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { UserContext } from '../../Access';
import { useContext } from 'react';
import { UserRole } from '../../enums/UserRole';
import InputField from './components/inputs/InputField';
import { debtService } from '../../service/debt.service';
import TinyLoader from './components/loaders/TinyLoader';

const ManageDebt = () => {
  const userSession = useContext(UserContext);
  const user = userSession.user;

  const [response, setResponse] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const [openRegisterDebtModal, setOpenRegisterDebtModal] = useState(false);
  const [openDebtRepaymentModal, setOpenDebtRepaymentModal] = useState(false);
  const [openDeleteDebtModal, setOpenDeleteDebtModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [totalPages, setTotalPage] = useState();
  let [currentPage, setCurrentPage] = useState(0);
  const { width } = useWindowDimensions();
  const [input, setInput] = useState({ from: '', to: '' });
  const [registerDebtInput, setRegisterDebtInput] = useState(
    { title: '', debtorName: '', debtorPhoneNumber: '', amount: '', description: '', repaymentDate: '' }
  );
  const [registerDebtProgress, setRegisterDebtProgress] = useState(false);

  const [repayDebtInput, setRepayDebtInput] = useState(
    { duid: '', amount: '', description: '' }
  );
  const [repayDebtProgress, setRepayDebtProgress] = useState(false);
  const [deleteProgress, setDeleteProgress] = useState(false);
  const [selectedDebt, setSelectedDebt] = useState(false);


  useEffect(() => {
    getAllDebts();
  }, []);

  const getAllDebts = () => {
    const request = {
      from: input.from,
      to: input.to,
    };
    setInProgress(true);
    debtService.getAllDebt(request, currentPage)
      .then((result) => {
        setInProgress(false);
        if (result.data.success) {
          setResponse(result.data.data.page);
        }
      })
      .catch((e) => {
        setResponse([]);
        setInProgress(false);
      });
  };

  const changePage = (action) => {
    if (action === -1) {
      if (currentPage > 0) currentPage--;
    } else if (action === +1) {
      if (currentPage < totalPages) currentPage++;
    } else {
      currentPage = action;
    }
    setCurrentPage(currentPage);
    if (searchText && searchText.length >= 3) performSearch();
    else getAllDebts();
  };

  const performSearch = () => {
    if (searchText && searchText.length >= 3) {

      const request = {
        from: input.from,
        to: input.to,
      };
      setInProgress(true);
      debtService.searchDebts(request, currentPage, searchText)
        .then((result) => {
          setInProgress(false);
          if (result.data.success) {
            const { page } = result.data.data;
            const { data } = result.data;
            setResponse(page);
            if (page.length > 0) {
              setTotalPage(data.totalPages);
              setCurrentPage(data.currentPage);
            }
          } else {
            setResponse([]);
          }
        })
        .catch((e) => {
          setResponse([]);
          setInProgress(false);
        });
    }
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
    performSearch();
  };


  const registerDebt = () => {
    if (registerDebtInput.debtorName === '' || registerDebtInput.debtorName.length < 3)
      toast.error("Provide valid debtor name")
    else if (registerDebtInput.debtorPhoneNumber === '' || registerDebtInput.debtorPhoneNumber.length !== 11)
      toast.error("Provide valid debtor phone number")
    else if (registerDebtInput.amount === '' || registerDebtInput.amount <= 0)
      toast.error("Provide valid amount")
    else {
      const request = {
        title: registerDebtInput.title,
        amount: Number(registerDebtInput.amount),
        debtorName: registerDebtInput.debtorName,
        debtorPhoneNumber: registerDebtInput.debtorPhoneNumber,
        description: registerDebtInput.description,
        repaymentDate: registerDebtInput.repaymentDate,
      }
      setRegisterDebtProgress(true);
      debtService.createDebt(request)
        .then((result) => {
          setRegisterDebtProgress(false);
          if (result.data.success) {
            getAllDebts();
            setOpenRegisterDebtModal(false);
            toast.success('Registered successful');
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setRegisterDebtProgress(false);
        });
    }
  }

  const repayDebt = () => {
    if (repayDebtInput.amount === '' || repayDebtInput.amount <= 0)
      toast.error("Provide valid amount")
    else {
      const request = {
        duid: selectedDebt.duid,
        amount: Number(repayDebtInput.amount),
        description: repayDebtInput.description,
      }
      setRepayDebtProgress(true);
      debtService.repayDebt(request)
        .then((result) => {
          setRepayDebtProgress(false);
          if (result.data.success) {
            getAllDebts();
            setOpenDebtRepaymentModal(false);
            toast.success('Repayment successful');
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setRepayDebtProgress(false);
        });
    }
  }

  const performDelete = () => {
    setDeleteProgress(true);
    debtService
      .deleteDebt(selectedDebt.duid)
      .then((result) => {
        setOpenDeleteDebtModal(false);
        setDeleteProgress(false);
        if (result.data.success) {
          toast.success('Deleted successful');
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.response.data.message);
        setOpenDeleteDebtModal(false);
        setDeleteProgress(false);
      });
  }
  return (
    <OtherPageBody>
      <ToastContainer />
      <Dialog open={openRegisterDebtModal}>
        <DialogTitle>Register Debt</DialogTitle>
        <DialogContent>

          <InputField
            type="text"
            name="debtorName"
            value={registerDebtInput.debtorName}
            placeholder="Debtor name"
            label="Debtor Name"
            onChange={(e) =>
              setRegisterDebtInput({
                ...registerDebtInput,
                debtorName: e.target.value,
              })
            }
            required
          />
          <InputField
            type="tel"
            maxLength="11"
            name="debtorPhoneNumber"
            value={registerDebtInput.debtorPhoneNumber}
            placeholder="Debtor Phone Number"
            label="Debtor Phon Number"
            onChange={(e) =>
              setRegisterDebtInput({
                ...registerDebtInput,
                debtorPhoneNumber: e.target.value,
              })
            }
            required
          />
          <InputField
            type="number"
            name="amount"
            value={registerDebtInput.amount}
            placeholder="Amount"
            label="Amount"
            onChange={(e) =>
              setRegisterDebtInput({
                ...registerDebtInput,
                amount: e.target.value,
              })
            }
            required
          />
          <InputField
            type="date"
            name="repaymentDate"
            value={registerDebtInput.repaymentDate}
            placeholder="Expected Repayment Date"
            label="Repayment Date"
            onChange={(e) =>
              setRegisterDebtInput({
                ...registerDebtInput,
                repaymentDate: e.target.value,
              })
            }
          />
          <InputField
            type="text"
            name="description"
            value={registerDebtInput.description}
            placeholder="Description"
            label="Remark"
            onChange={(e) =>
              setRegisterDebtInput({
                ...registerDebtInput,
                description: e.target.value,
              })
            }
          />

        </DialogContent>
        <DialogActions>
          <button
            onClick={() => setOpenRegisterDebtModal(false)}
            className="btn btn-primary btn-border btn-round mr-2"
          >
            Cancel
          </button>
          <button
            onClick={!registerDebtProgress ? registerDebt : null}
            className="btn btn-primary btn-round"
          >
            {registerDebtProgress ? <TinyLoader /> : 'Continue'}
          </button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDebtRepaymentModal}>
        <DialogTitle>Repay Debt</DialogTitle>
        <DialogContent>
          <InputField
            type="number"
            name="amount"
            value={repayDebtInput.amount}
            placeholder="Amount"
            label="Amount"
            onChange={(e) =>
              setRepayDebtInput({
                ...repayDebtInput,
                amount: e.target.value,
              })
            }
            required
          />
          <InputField
            type="text"
            name="description"
            value={repayDebtInput.description}
            placeholder="Description"
            label="Remark"
            onChange={(e) =>
              setRepayDebtInput({
                ...repayDebtInput,
                description: e.target.value,
              })
            }
          />

        </DialogContent>
        <DialogActions>
          <button
            onClick={() => setOpenDebtRepaymentModal(false)}
            className="btn btn-primary btn-border btn-round mr-2"
          >
            Cancel
          </button>
          <button
            onClick={!repayDebtProgress ? repayDebt : null}
            className="btn btn-primary btn-round"
          >
            {repayDebtProgress ? <TinyLoader /> : 'Continue'}
          </button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteDebtModal}>
        <DialogTitle>Delete Debt</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this debt?</p>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpenDeleteDebtModal(false);
            }}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            No
          </button>
          <button
            onClick={!deleteProgress ? performDelete : null}
            className="btn btn-primary btn-round"
          >
            {deleteProgress ? <TinyLoader /> : 'Yes'}
          </button>
        </DialogActions>
      </Dialog>


      <div className="page-inner">
        <div className="row">
          <div className="col-md-12">
            <div className="header-card">
              <div className="card-header">
                <div className="d-flex align-items-center">
                  <h4 className="card-title">All Debts</h4>
                  <SearchField
                    value={searchText}
                    placeholder="Search debtor name e.g Aisha"
                    onChange={onSearch}
                    onClick={performSearch}
                  />
                  <button
                    onClick={() => setOpenRegisterDebtModal(true)}
                    className="btn btn-primary  btn-round ml-auto btn-large link-btn"
                  >
                    <i className="fa fa-plus" style={{ margin: '5px' }}></i>
                    Register Debt
                  </button>
                </div>
              </div>
            </div>
            <div className="filter-container d-flex align-item-center text-align-center">
              <InputField
                type="date"
                name="from"
                value={input.from}
                placeholder="From"
                label="From"
                onChange={(e) =>
                  setInput({
                    ...input,
                    from: e.target.value,
                  })
                }
              /><InputField
                type="date"
                name="to"
                value={input.to}
                placeholder="To"
                label="To"
                onChange={(e) =>
                  setInput({
                    ...input,
                    to: e.target.value,
                  })
                }
              />
              <div className="filter-button text-align-right">
                <button
                  type="button"
                  onClick={() => {
                    getAllDebts()
                  }}
                  className="btn default-btn btn-primary btn-large p-2"
                >

                  Filter
                </button>
              </div>
            </div>
            <div className="row data-wrapper">
              {inProgress ? (
                <div className="row card">
                  <Loader></Loader>
                </div>
              ) : response.length > 0 ? (
                width > 800 ? (
                  <div className="row card">
                    <div className="card-body">
                      <table
                        id="add-row"
                        className=" table table-striped"
                      >
                        <thead>
                          <tr>
                            <th>S/n</th>
                            <th>Debtor</th>
                            <th>Debtor Number</th>
                            <th>Total Amount</th>
                            <th>Cleared Amount</th>
                            <th>Balance</th>
                            <th>Remark</th>
                            <th>Date</th>
                          </tr>
                        </thead>

                        <tbody>
                          {response.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{++index}</td>
                                <td>{item.debtorName}</td>
                                <td className="text-success"><a href={'tel:' + item.debtorPhoneNumber}><i className="fa fa-phone"></i> {item.debtorPhoneNumber}</a></td>
                                <td>₦{toMoney(item.amount)}</td>
                                <td className="text-success">₦{toMoney(item.clearedAmount)}</td>
                                <td className="text-danger">₦{toMoney(item.amount - item.clearedAmount)}</td>
                                <td>{item.description ? item.description : 'N/A'}</td>
                                <td>{format(item.createdAt)}</td>
                                <td>
                                  <div className="form-button-action">
                                    <button
                                      type="button"
                                      data-toggle="tooltip"
                                      title=""
                                      className="btn  btn-primary btn-outline bg-danger"
                                      data-original-title="repay"
                                      onClick={() => {
                                        setSelectedDebt(item)
                                        setOpenDeleteDebtModal(true);
                                      }}
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                    &nbsp;
                                    <button
                                      type="button"
                                      data-toggle="tooltip"
                                      title=""
                                      className="btn  btn-primary "
                                      data-original-title="repay"
                                      onClick={() => {
                                        setSelectedDebt(item)
                                        setOpenDebtRepaymentModal(true);
                                      }}
                                    >
                                      <i className="fa fa-share"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  response.map((item, index) => {
                    return (
                      <div
                        className="list-card-item col-xl-2 col-lg-4 col-md-4 col-12"
                        key={index}
                      >
                        <div className="image-flip">
                          <div className="mainflip flip-0">
                            <div className="frontside">
                              <div className="card">
                                <div className="invoice-ribbon">
                                  <div className="ribbon-inner  bg-primary">
                                    {item.clearedAmount >= item.amount ? 'Closed' : 'Open'}
                                  </div>
                                </div>
                                <div className="card-body text-left">
                                  <h4 className="card-title text-primary">
                                    {item.name}
                                  </h4>
                                  <ul className="card-text">
                                    <li>
                                      <h2><strong>{item.debtorName}</strong></h2>
                                    </li>
                                    <li>
                                      <h2><strong className="text-success"><i className="fa fa-phone"></i> <a href={'tel:' + item.debtorPhoneNumber}>{item.debtorPhoneNumber}</a></strong></h2>
                                    </li>
                                    <li>
                                      Total Amount:{' '}
                                      <span className="text-danger">
                                        ₦{toMoney(item.amount)}
                                      </span>
                                    </li>
                                    <li>
                                      Cleared Amount:{' '}
                                      <span className="text-success">
                                        ₦{toMoney(item.clearedAmount)}
                                      </span>
                                    </li>
                                    <li>
                                      Balance:{' '}
                                      <span className="text-danger">
                                        {' '}
                                        ₦{toMoney(item.amount - item.clearedAmount)}
                                      </span>
                                    </li>
                                    <li>Created :<span className="text-primary">{format(item.createdAt)}</span></li>
                                    <li>
                                      Status :{' '}
                                      <span className="text-success">
                                        {item.status}
                                      </span>
                                    </li>
                                  </ul>

                                  <div className="form-button-action">
                                    <button
                                      type="button"
                                      data-toggle="tooltip"
                                      title=""
                                      className="btn btn-primary bg-danger"
                                      data-original-title="view"
                                      onClick={() => {
                                        setSelectedDebt(item)
                                        setOpenDeleteDebtModal(true);
                                      }}
                                    >
                                      <i className="fa fa-times"></i> Delete
                                    </button>
                                    &nbsp;
                                    <button
                                      type="button"
                                      data-toggle="tooltip"
                                      title=""
                                      className="btn btn-primary"
                                      data-original-title="view"
                                      onClick={() => {
                                        setSelectedDebt(item)
                                        setOpenDebtRepaymentModal(true);
                                      }}
                                    >
                                      <i className="fa fa-plus"></i> Repay
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )
              ) : (
                <div className="row card">
                  <div className="text-center message-box">
                    <p>No Debt found</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-end">
            <li className="page-item">
              <button className="page-link" onClick={() => changePage(-1)}>
                Previous
              </button>
            </li>
            {[...Array(totalPages)].map((e, i) => (
              <li className="page-item" key={i}>
                <button
                  className={
                    i === currentPage
                      ? 'page-link btn-primary text-white'
                      : 'page-link'
                  }
                  onClick={() => changePage(i)}
                >
                  {i + 1}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button className="page-link" onClick={() => changePage(+1)}>
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </OtherPageBody>
  );
};

export default ManageDebt;
