import React, { useState, useContext } from 'react';
import OtherPageBody from './OtherPageBody';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { inventoryService } from '../../service/inventory.service';
import InputField from './components/inputs/InputField';
import { ToastContainer, toast } from 'react-toastify';
import toMoney from 'number-to-money';
import signature from './../../assets/img/signature.png';
import { useRef } from 'react';
import { UserContext } from '../../Access';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import TinyLoader from './components/loaders/TinyLoader';
import { useReactToPrint } from 'react-to-print';
import { useParams } from 'react-router-dom';
import OptionField from './components/select/OptionField';
import { TransactionType } from '../../enums/TransactionType';
import { util } from '../../utils/constants/constants';
import { titleCase } from 'title-case';
import { format } from 'timeago.js';
import { fileService } from '../../service/file.service';

const CreateTransaction = () => {
  const userSession = useContext(UserContext);
  const user = userSession.user;
  const { type } = useParams();
  const [selectedType, setSelectedType] = useState(type.toUpperCase());
  const [transactionDetail, setTransactionDetail] = useState(null);
  const [snappedCustomerId, setSnappedCustomerId] = useState(null);
  const [snappedCustomerPicture, setSnappedCustomerPicture] = useState(null);
  const [openReceiptModal, setOpenReceiptModal] = useState(true);
  const receiptRef = useRef();
  const [salesProgress, setSalesProgress] = useState(false);
  const { width } = useWindowDimensions();
  const [salesInput, setSalesInput] = useState({
    customerPhoneNumber: '',
    customerName: '',
    customerId: '',
    bankName: '',
    accountName: '',
    accountNumber: '',
    customerPicture: '',
    transactionType: '',
    beneficiary: '',
    channel: '',
    amount: 0,
    revenue: 0,
  });

  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
    onAfterPrint: () => createNewTransaction,
  });

  const createNewTransaction = () => {
    setOpenReceiptModal(false);
    setSalesInput({
      customerPhoneNumber: '',
      customerName: '',
      customerId: '',
      bankName: '',
      accountName: '',
      accountNumber: '',
      customerPicture: '',
      transactionType: '',
      beneficiary: '',
      channel: '',
      amount: 0,
      revenue: 0,
    });
  };


  const validateDeposit = () => {
    if (salesInput.customerName === '' || salesInput.customerName.length < 3)
      toast.error('Valid customer name required');
    else if (salesInput.customerPhoneNumber === '' || salesInput.customerPhoneNumber.length !== 11)
      toast.error('Valid customer number required');
    else if (salesInput.bankName === '' || salesInput.bankName === 'Select bank...')
      toast.error('Valid bank name required');
    else if (salesInput.accountName === '' || salesInput.accountName.length < 3)
      toast.error('Valid account name required');
    else if (salesInput.accountNumber === '' || salesInput.accountNumber.length < 10)
      toast.error('Valid account number required');
    else {
      const request = {
        customerPhoneNumber: salesInput.customerPhoneNumber,
        customerName: salesInput.accountName,
        bankName: salesInput.bankName,
        accountName: salesInput.accountName,
        accountNumber: salesInput.accountNumber,
        transactionType: selectedType,
      }
      return request;
    }
  }

  const validateWithdrawal = () => {
    if (salesInput.customerName === '' || salesInput.customerName.length < 3)
      toast.error('Valid customer name required');
    else if (salesInput.customerPhoneNumber === '' || salesInput.customerPhoneNumber.length !== 11)
      toast.error('Valid customer number required');
    else if (salesInput.channel === '' || salesInput.channel === 'Select channel...')
      toast.error('Transaction channel required');
    else {
      let valid = false;
      if (salesInput.channel === 'USSD' || salesInput.channel === 'OTP') {
        if (salesInput.idCard === '' || salesInput.idCard.length < 3)
          toast.error('Valid customer ID required');
        else if (salesInput.picture === '' || salesInput.picture.length < 3)
          toast.error('Valid customer picture required');
        else
          valid = true
      }
      if ((salesInput.channel !== 'USSD' && salesInput.channel !== 'OTP') || valid) {
        const request = {
          customerPhoneNumber: salesInput.customerPhoneNumber,
          customerName: salesInput.customerName,
          customerId: salesInput.idCard,
          customerPicture: salesInput.picture,
          channel: salesInput.channel,
          transactionType: selectedType,
        }
        return request;
      }
    }
  }


  const validateBillPayment = () => {
    if (salesInput.customerName === '' || salesInput.customerName.length < 3)
      toast.error('Valid customer name required');
    else if (salesInput.customerPhoneNumber === '' || salesInput.customerPhoneNumber.length !== 11)
      toast.error('Valid customer number required');
    else if (salesInput.serviceType === '' || salesInput.serviceType === 'Select service type...')
      toast.error('Service type required');
    else if (salesInput.beneficiary === '' || salesInput.beneficiary.length < 10)
      toast.error('Valid Beneficiary ID required');
    else {
      const request = {
        customerPhoneNumber: salesInput.customerPhoneNumber,
        customerName: salesInput.customerName,
        beneficiary: salesInput.beneficiary,
        serviceType: salesInput.serviceType,
        transactionType: selectedType,
      }
      return request;
    }
  }
  const createTransaction = async () => {
    let oderRequest;
    if (selectedType === TransactionType.DEPOSIT) {
      oderRequest = validateDeposit();
    }
    if (selectedType === TransactionType.BILLPAYMENT) {
      oderRequest = validateBillPayment();
    }
    if (selectedType === TransactionType.WITHDRAWAL) {
      oderRequest = validateWithdrawal();
    }


    if (oderRequest) {
      var valid = false;
      if (salesInput.amount === '' || Number(salesInput.amount) <= 0) {
        toast.error('Valid amount required');
      }
      else {
        valid = true;
      }

      if (valid === true) {

        const request = {
          ...oderRequest,
          amount: Number(salesInput.amount),
          revenue: salesInput.revenue ? Number(salesInput.revenue) : 0,
          discount: 0,
        };
        setSalesProgress(true);

        if (selectedType === TransactionType.WITHDRAWAL) {
          const filesData = new FormData();

          if (oderRequest.customerId && oderRequest.customerId.length >= 2)
            filesData.append('files[]', snappedCustomerId);
          if (oderRequest.customerPicture && oderRequest.customerPicture.length >= 2)
            filesData.append('files[]', snappedCustomerPicture);

          const fileResponse = await fileService.uploadFiles(filesData);
          if (fileResponse.data.success) {
            const fileURLs = fileResponse.data.data;
            if (fileURLs.length > 0) {
              if (fileURLs[0])
                request.customerId = fileURLs[0].url
              if (fileURLs[1])
                request.customerPicture = fileURLs[1].url
            }
          }
        }

        inventoryService.createTransaction(request)
          .then((result) => {
            setSalesProgress(false);
            if (result.data.success) {
              setTransactionDetail(result.data.data);
              setOpenReceiptModal(true);
            } else {
              toast.error(result.data.message);
            }
          })
          .catch((e) => {
            setSalesProgress(false);
            toast.error(
              e.response?.data?.message
                ? Array.isArray(e.response?.data?.message)
                  ? e.response?.data?.message.join(' ')
                  : e.response?.data?.message
                : e.message,
            );
          });
      }

    };
  }
  return (
    <OtherPageBody>
      <ToastContainer />
      <Dialog open={openReceiptModal && transactionDetail !== null}>
        <DialogTitle>Transaction Receipt</DialogTitle>
        <DialogContent>
          {transactionDetail ? (
            <div className="row">
              <div className="col-md-12">
                <div className="panel panel-default invoice" ref={receiptRef}>
                  <div className="panel-body">
                    <div className="invoice-ribbon">
                      <div className="ribbon-inner  bg-primary">Paid</div>
                    </div>
                    <div className="row  p-5">
                      <div className="row">
                        <div className={width > 800 ? 'col-sm-6  mt-5' : 'col-sm-6'}>
                          <h3 className="marginright">{user.business}</h3>
                          <span className="marginright">
                            {transactionDetail.createdBy}
                          </span>
                        </div>
                        <div className={width > 800 ? 'col-sm-6  mt-5 top-right' : 'col-sm-6 top-right'}>
                          <h3 className="marginright">
                            REF-{transactionDetail.code}
                          </h3>
                          <span className="marginright">
                            {new Date(transactionDetail.createdAt).toISOString()}
                          </span>
                        </div>
                      </div>
                      <hr />
                      <div className="mt-4  mb-3">
                        <span className="marginright">
                          {titleCase(transactionDetail.transactionType.toLowerCase())} transaction by{' '}
                          <strong>{transactionDetail.customerName}</strong> with phone
                          number{' '}
                          <strong>{transactionDetail.customerPhoneNumber}</strong>

                          {transactionDetail.transactionType === TransactionType.WITHDRAWAL ?
                            <>
                              <strong>{transactionDetail.serviceType}</strong>
                              &nbsp;via {titleCase(transactionDetail.channel)} &nbsp;
                              {format(transactionDetail.createdAt)}
                            </> : ''}

                          {transactionDetail.transactionType === TransactionType.DEPOSIT ?
                            <>
                              &nbsp;deposited to {transactionDetail.bankName},  {transactionDetail.accountNumber}
                              <strong> {transactionDetail.accountName}</strong> {format(transactionDetail.createdAt)}
                            </> : ''}

                          {transactionDetail.transactionType === TransactionType.BILLPAYMENT ?
                            <>
                              &nbsp; to <strong>{transactionDetail.beneficiary}</strong> about {format(transactionDetail.createdAt)}
                            </> : ''}
                        </span>
                      </div>

                      <div className="row">
                        <div className="summary-wrapper col-sm-6">
                          {transactionDetail.customerPicture ?
                            <img src={transactionDetail.customerPicture} className="card customer-pic" alt="customer" />
                            : <></>}
                          <img src={signature} alt="signature mt-3" />
                        </div>
                        <div className="col-sm-6 text-right pull-right invoice-total mt-5">
                          <p>Subtotal : ₦{toMoney(transactionDetail.amount)}</p>
                          <p>
                            Discount : ₦{toMoney(0)}
                          </p>
                          <p>VAT : ₦0 </p>
                          <p>
                            Total : ₦
                            {toMoney(
                              transactionDetail.amount
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <p>Unable to generate receipt</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-secondary btn-round mr-2"
            onClick={() => handlePrint()}
          >
            Print
          </button>

          <button
            className="btn btn-danger  btn-round mr-2"
            onClick={() => {
              createNewTransaction();
              setOpenReceiptModal(false)
            }}
          >
            Okay
          </button>
        </DialogActions>
      </Dialog>
      <div className="page-inner">
        <div className="page-inner">
          <div className="row">
            <div className="col-md-12">
              <div className="header-card">
                <div className="card-header">
                  <div className="d-flex align-items-center">
                    <h4 className="card-title">Create Transaction</h4>
                    <button
                      className="btn btn-primary  btn-round ml-auto btn-large"
                      onClick={createNewTransaction}
                    >
                      <i className="fa fa-plus" style={{ margin: '5px' }}></i>
                      Create New Transaction
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt--2">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6 mb-1">
                        <OptionField
                          children={[type ? titleCase(type) : 'Select transaction type..', 'Deposit', 'Withdrawal', 'BillPayment']}
                          name="type"
                          value={salesInput.type}
                          placeholder="Transaction type"
                          label="Transaction Type"
                          onChange={(e) => {
                            setSelectedType(e.target.value.toUpperCase())
                            setSalesInput({
                              ...salesInput,
                              type: e.target.value,
                            })
                          }
                          }
                          required
                        />
                      </div>
                      {selectedType === TransactionType.BILLPAYMENT ?
                        <div className="col-md-6 mb-1">
                          <OptionField
                            children={['Select service type..', 'Airtime', 'Data', 'TV', 'Electricity']}
                            name="serviceType"
                            value={salesInput.serviceType}
                            placeholder="Service type"
                            label="Service Type"
                            onChange={(e) => {
                              setSalesInput({
                                ...salesInput,
                                serviceType: e.target.value,
                              })
                            }
                            }
                            required
                          />
                        </div>
                        : <></>}


                      {selectedType === TransactionType.BILLPAYMENT ?
                        <div className="col-md-6 mb-1">
                          <InputField
                            type="number"
                            name="beneficiary"
                            value={salesInput.beneficiary}
                            placeholder={salesInput.serviceType === 'Airtime' || salesInput.serviceType === 'Data' ? 'Phone Number' :
                              salesInput.serviceType === 'TV' ? 'Enter the IUC Number' : 'Enter the Mitre Number'}
                            label={salesInput.serviceType === 'Airtime' || salesInput.serviceType === 'Data' ? 'Beneficiary Phone Number' :
                              salesInput.serviceType === 'TV' ? 'IUC Number' : 'Mitre Number'}
                            onChange={(e) =>
                              setSalesInput({
                                ...salesInput,
                                beneficiary: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        : <></>}

                      {selectedType === TransactionType.WITHDRAWAL ?

                        <div className="col-md-6 mb-1">
                          <OptionField
                            children={['Select channel..', 'Card', 'Transfer', 'OTP', 'USSD']}
                            name="channel"
                            value={salesInput.channel}
                            placeholder="Withdrawal Channel"
                            label="Withdrawal Channel"
                            onChange={(e) => {
                              setSalesInput({
                                ...salesInput,
                                channel: e.target.value,
                              })
                            }
                            }
                            required
                          />
                        </div>
                        : <></>}
                      <div className="col-md-6 mb-1">
                        <InputField
                          type="text"
                          name="name"
                          value={salesInput.customerName}
                          placeholder="Name"
                          label="Customer Name"
                          onChange={(e) =>
                            setSalesInput({
                              ...salesInput,
                              customerName: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-1">
                        <InputField
                          type="text"
                          name="Phone Number"
                          value={salesInput.customerPhoneNumber}
                          placeholder="Phone Number"
                          maxLength="11"
                          label="Customer Number"
                          onChange={(e) =>
                            setSalesInput({
                              ...salesInput,
                              customerPhoneNumber: e.target.value,
                            })
                          }
                          required
                        />
                      </div>

                      {selectedType === TransactionType.DEPOSIT ?
                        <div className="col-md-6 mb-1">
                          <OptionField
                            children={util.getBanks()}
                            name="bankName"
                            value={salesInput.bankName}
                            placeholder="Bank Name"
                            label="Customer Bank Name"
                            onChange={(e) =>
                              setSalesInput({
                                ...salesInput,
                                bankName: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        : <></>}


                      {selectedType === TransactionType.DEPOSIT ?
                        <div className="col-md-6 mb-1">
                          <InputField
                            type="number"
                            name="accountNumber"
                            value={salesInput.accountNumber}
                            placeholder="Account Number"
                            maxLength="10"
                            label="Account Number"
                            onChange={(e) =>
                              setSalesInput({
                                ...salesInput,
                                accountNumber: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        : <></>}

                      {selectedType === TransactionType.DEPOSIT ?
                        <div className="col-md-6 mb-1">
                          <InputField
                            type="text"
                            name="accountName"
                            value={salesInput.accountName}
                            placeholder="Account Name"
                            label="Account Name"
                            onChange={(e) =>
                              setSalesInput({
                                ...salesInput,
                                accountName: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        : <></>}



                      {selectedType !== TransactionType.BILLPAYMENT
                        && selectedType !== TransactionType.DEPOSIT ?
                        <div className="col-md-6 mb-1">
                          <InputField
                            type="file"
                            name="picture"
                            value={salesInput.picture}
                            accept="capture=camera"
                            placeholder="Customer Picture"
                            label="Customer Picture"
                            onChange={(e) => {
                              setSnappedCustomerPicture(e.target.files[0])
                              setSalesInput({
                                ...salesInput,
                                picture: e.target.value,
                              })
                            }
                            }
                            required={salesInput.channel === 'USSD' || salesInput.channel === 'OTP' ? true : false}
                          />
                        </div>
                        : <></>}

                      {selectedType !== TransactionType.BILLPAYMENT
                        && selectedType !== TransactionType.DEPOSIT ?
                        <div className="col-md-6 mb-1">
                          <InputField
                            type="file"
                            name="idCard"
                            value={salesInput.idCard}
                            accept="capture=camera"
                            placeholder="Customer Valid ID"
                            label="Customer ID"
                            onChange={(e) => {
                              setSnappedCustomerId(e.target.files[0])
                              setSalesInput({
                                ...salesInput,
                                idCard: e.target.value,
                              })
                            }
                            }
                            required={salesInput.channel === 'USSD' || salesInput.channel === 'OTP' ? true : false}
                          />
                        </div>
                        : <></>}

                      <div className="col-md-6 mb-1">
                        <InputField
                          type="number"
                          name="amount"
                          value={salesInput.amount}
                          placeholder="Amount"
                          label="Transaction Amount"
                          onChange={(e) =>
                            setSalesInput({
                              ...salesInput,
                              amount: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-1">
                        <InputField
                          type="number"
                          name="revenue"
                          value={salesInput.revenue}
                          placeholder="Commission Charges (Optional)"
                          label="Transaction Commission"
                          onChange={(e) =>
                            setSalesInput({
                              ...salesInput,
                              revenue: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="action-buttons d-flex flex-sm-row flex-column align-item-left">
                      <button
                        onClick={!salesProgress ? createTransaction : null}
                        className="btn btn-secondary btn-round mr-2  p-3 btn-large mb-3"
                      >
                        {salesProgress
                          ? <TinyLoader />
                          : 'Submit Transaction'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </OtherPageBody >
  );
};

export default CreateTransaction;
