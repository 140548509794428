import React, { useState, useEffect } from 'react';
import OtherPageBody from './OtherPageBody';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { format } from 'timeago.js';
import { titleCase } from 'title-case';
import { inventoryService } from '../../service/inventory.service';
import Loader from './components/loaders/Loader';
import SearchField from './components/inputs/SearchField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputField from './components/inputs/InputField';
import OptionField from './components/select/OptionField';
import { productService } from '../../service/product.service';

import toMoney from 'number-to-money';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import TinyLoader from './components/loaders/TinyLoader';

const InventoryProducts = () => {
  const [allInventories, setAllInventories] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [inProgress, setInProgress] = useState(true);
  const [totalPages, setTotalPage] = useState();
  let [currentPage, setCurrentPage] = useState(0);
  const [newProductInput, setNewProductInput] = useState({
    title: '',
    type: '',
    category: '',
    quantity: 0,
    purchasePrice: 0,
    sellingPrice: 0,
    size: '',
    description: '',
  });
  const [productTypeInput, setProductTypeInput] = useState({
    title: '',
    category: '',
    description: '',
  });
  const [productInputProgress, setProductInputProgress] = useState(false);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openUpdateProgress, setOpenUpdateProgress] = useState(false);
  const [productTypeProgress, setProductTypeProgress] = useState(false);
  const [openProductTypeModal, setOpenProductTypeModal] = useState(false);
  const [openAdjustModal, setOpenAdjustModal] = useState(false);
  const [adjustProgress, setAdjustProgress] = useState(false);
  const [adjustInput, setAdjustInput] = useState({
    quantity: 0,
    operation: '',
    reason: '',
  });
  const [productType, setProductType] = useState(['Select Type...']);
  const [productCategory, setProductCategory] = useState([
    'Select Category...',
  ]);
  const [productSize, setProductSize] = useState(['Select Size...']);
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const getInventoryProducts = () => {
    setInProgress(true);
    inventoryService
      .getInventoryProducts(currentPage)
      .then((result) => {
        setInProgress(false);
        if (result.data.success) {
          console.log(result);
          const { page } = result.data.data;
          const { data } = result.data;
          setAllInventories(page);
          if (page.length > 0) {
            setTotalPage(data.totalPages);
            setCurrentPage(data.currentPage);
          }
        }
      })
      .catch((e) => {
        setInProgress(false);
      });
  };

  const createNewProduct = () => {
    if (newProductInput.title === '' || newProductInput.title.length < 2)
      toast.error('Provide valid title');
    else if (newProductInput.type === '' || newProductInput.type.length < 3)
      toast.error('Valid type required');
    else if (newProductInput.size === '' || newProductInput.size.length < 3)
      toast.error('Valid Size required');
    else if (newProductInput.purchasePrice < 0)
      toast.error('Valid Purchase Price required');
    else if (newProductInput.sellingPrice < 0)
      toast.error('Valid Selling Price required');

    if (
      newProductInput.title !== '' &&
      newProductInput.title.length >= 2 &&
      newProductInput.type !== '' &&
      newProductInput.type.length >= 3 &&
      newProductInput.size !== '' &&
      newProductInput.size.length >= 3 &&
      newProductInput.purchasePrice >= 0 &&
      newProductInput.sellingPrice >= 0
    ) {
      const request = {
        title: newProductInput.title,
        description: newProductInput.description,
        type: newProductInput.type,
        size: newProductInput.size,
        quantity: newProductInput.quantity && Number(newProductInput.quantity) ? Number(newProductInput.quantity) : 0,
        purchasePrice: Number(newProductInput.purchasePrice),
        sellingPrice: Number(newProductInput.sellingPrice),
      };
      setProductInputProgress(true);
      inventoryService
        .createProduct(request)
        .then((result) => {
          setOpenProductModal(false);
          setProductInputProgress(false);
          if (result.data.success) {
            toast.success('Created successful');
            getInventoryProducts();
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          toast.error(
            e.response?.data?.message
              ? Array.isArray(e.response?.data?.message)
                ? e.response?.data?.message.join(' ')
                : e.response?.data?.message
              : e.message,
          );
          setProductInputProgress(false);
        });
    }
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
    performSearch();
  };

  const performSearch = () => {
    if (searchText && searchText.length >= 3) {
      setInProgress(true);
      inventoryService
        .searchSales(currentPage, searchText)
        .then((result) => {
          setInProgress(false);
          if (result.data.success) {
            const { page } = result.data.data;
            const { data } = result.data;
            setAllInventories(page);
            if (page.length > 0) {
              setTotalPage(data.totalPages);
              setCurrentPage(data.currentPage);
            }
          } else {
            setAllInventories([]);
          }
        })
        .catch((e) => {
          setAllInventories([]);
          setInProgress(false);
        });
    }
  };

  const UpdateProductsProduct = () => {
    const request = {
      title: newProductInput.title,
      description: newProductInput.description,
      type: newProductInput.type,
      size: newProductInput.size,
      quantity: newProductInput.quantity && Number(newProductInput.quantity) ? Number(newProductInput.quantity) : 0,
      purchasePrice: Number(newProductInput.purchasePrice),
      sellingPrice: Number(newProductInput.sellingPrice),
    };
    if (selectedProduct) {
      inventoryService
        .updateProduct(selectedProduct.puid, request)
        .then((result) => {
          setOpenUpdateModal(false);
          setOpenUpdateProgress(false);
          if (result.data.success) {
            toast.success('Updated successful');
            getInventoryProducts();
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setOpenUpdateModal(false);
          setOpenUpdateProgress(false);
        });
    } else {
      toast.error('No product selected');
    }
  };

  const closeProductInputModal = () => {
    setOpenProductModal(false);
  };

  const closeUpdateModal = () => {
    setOpenUpdateModal(false);
  };
  const performAdjust = () => {
    if (selectedProduct) {
      if (!adjustInput.quantity || Number(adjustInput.quantity) <= 0)
        toast.error('Enter valid quantity');
      else if (
        adjustInput.operation === '' ||
        adjustInput.operation.length < 3
      )
        toast.error('Do you want to add or subtract');

      if (
        adjustInput.quantity &&
        Number(adjustInput.quantity) > 0 &&
        adjustInput.operation !== '' &&
        adjustInput.operation.length >= 3
      ) {
        const request = {
          reason: adjustInput.reason,
          operation: adjustInput.operation,
          quantity: Number(adjustInput.quantity),
        };
        setAdjustProgress(true);
        inventoryService
          .adjustProduct(selectedProduct.puid, request)
          .then((result) => {
            setOpenAdjustModal(false);
            setAdjustProgress(false);
            if (result.data.success) {
              toast.success('Transfer successful');
              getInventoryProducts();
            } else {
              toast.error(result.data.message);
            }
          })
          .catch((e) => {
            console.log(e);
            toast.error(e.response.data.message);
            setOpenAdjustModal(false);
            setAdjustProgress(false);
          });
      }
    }
  };

  const changePage = (action) => {
    if (action === -1) {
      if (currentPage > 0) currentPage--;
    } else if (action === +1) {
      if (currentPage < totalPages) currentPage++;
    } else {
      currentPage = action;
    }
    setCurrentPage(currentPage);
    if (searchText && searchText.length >= 3) performSearch();
    else getInventoryProducts();
  };

  const getAllProductType = () => {
    productService
      .getAllProductType()
      .then((result) => {
        if (result.data.success) {
          const resources = result.data.data;
          console.log(resources);
          const resourceList = ['Select Type...'];
          resources.forEach((resource) => {
            resourceList.push(resource.title);
          });

          setProductType(resourceList);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.data.message);
      });
  };

  const getAllProductCategory = () => {
    productService
      .getAllProductCategory()
      .then((result) => {
        if (result.data.success) {
          const resources = result.data.data;
          const resourceList = ['Select Category...'];
          resources.forEach((resource) => {
            resourceList.push(resource.title);
          });
          setProductCategory(resourceList);
          console.log(productCategory);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.data.message);
      });
  };

  const getAllProductSize = () => {
    productService
      .getAllProductSize()
      .then((result) => {
        if (result.data.success) {
          const resources = result.data.data;
          const resourceList = ['Select Size...'];
          resources.forEach((resource) => {
            resourceList.push(resource.title);
          });
          console.log(result, resourceList);
          setProductSize(resourceList);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.data.message);
      });
  };

  const createProductType = () => {
    if (productTypeInput.category === 'Select product category...')
      toast.error('Select product category');
    else if (productTypeInput.title === '' || productTypeInput.title.length < 3)
      toast.error('Provide valid title');

    if (
      productTypeInput.title !== '' &&
      productTypeInput.title.length >= 3 &&
      productTypeInput.category !== 'Select product category...'
    ) {
      const request = {
        title: productTypeInput.title,
        category: productTypeInput.category,
        description: 'N/A',
      };
      setProductTypeProgress(true);
      productService
        .createProductType(request)
        .then((result) => {
          setOpenProductTypeModal(false);
          setProductTypeProgress(false);
          if (result.data.success) {
            toast.success('Created successful');
            getAllProductType();
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(e.response.data.message);
          setOpenProductTypeModal(false);
          setProductTypeProgress(false);
        });
    }
  };

  const closeProductTypeModal = () => {
    setOpenProductTypeModal(false);
  };
  useEffect(() => {
    getInventoryProducts();
    getAllProductCategory();
    getAllProductSize();
    getAllProductType();
  }, []);

  return (
    <OtherPageBody>
      <ToastContainer />
      <Dialog open={openProductModal}>
        <DialogTitle>Add New Product</DialogTitle>
        <DialogContent>
          <InputField
            type="text"
            name="title"
            value={newProductInput.title}
            placeholder="Title"
            label="Product Title"
            onChange={(e) =>
              setNewProductInput({
                ...newProductInput,
                title: e.target.value,
              })
            }
            required
          />

          <InputField
            name="description"
            label="Description"
            placeholder="Description"
            value={newProductInput.description}
            onChange={(e) =>
              setNewProductInput({
                ...newProductInput,
                description: e.target.value,
              })
            }
          />

          <div className="row">
            <div className="col">
              <OptionField
                children={productType}
                label="Product Type"
                name="type"
                onChange={(e) => {
                  console.log(e.target.value);
                  setNewProductInput({
                    ...newProductInput,
                    type: e.target.value,
                  });
                }}
                required
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <InputField
                type="number"
                name="quantity"
                value={newProductInput.purchasePrice}
                placeholder="Purchase Price"
                label="Purchase Price"
                onChange={(e) =>
                  setNewProductInput({
                    ...newProductInput,
                    purchasePrice: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="col">
              <InputField
                type="number"
                name="quantity"
                value={newProductInput.sellingPrice}
                placeholder="Selling Price"
                label="Selling Price"
                onChange={(e) =>
                  setNewProductInput({
                    ...newProductInput,
                    sellingPrice: e.target.value,
                  })
                }
                required
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <OptionField
                children={productSize}
                label="Selling Price Per"
                name="size"
                onChange={(e) => {
                  console.log(e.target.value);
                  setNewProductInput({
                    ...newProductInput,
                    size: e.target.value,
                  });
                }}
                required
              />
            </div>
            <div className="col">
              <InputField
                type="number"
                name="quantity"
                value={newProductInput.quantity}
                placeholder="Quantity"
                label="How many available?"
                onChange={(e) =>
                  setNewProductInput({
                    ...newProductInput,
                    quantity: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={closeProductInputModal}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={!productInputProgress ? createNewProduct : null}
            className="btn btn-primary btn-round"
          >
            {productInputProgress ? <TinyLoader /> : 'Continue'}
          </button>
        </DialogActions>
      </Dialog>
      <Dialog open={openUpdateModal}>
        <DialogTitle>Update Product</DialogTitle>
        <DialogContent>
          <InputField
            type="text"
            name="title"
            value={newProductInput.title}
            placeholder="Title"
            label="Title"
            onChange={(e) =>
              setNewProductInput({
                ...newProductInput,
                title: e.target.value,
              })
            }
          />

          <InputField
            type="number"
            name="purchasePrice"
            value={newProductInput.purchasePrice}
            placeholder="Purchase Price"
            label="Purchase Price"
            onChange={(e) =>
              setNewProductInput({
                ...newProductInput,
                purchasePrice: e.target.value,
              })
            }
            required
          />

          <InputField
            type="number"
            name="sellingPrice"
            value={newProductInput.sellingPrice}
            placeholder="Selling Price"
            label="Selling Price"
            onChange={(e) =>
              setNewProductInput({
                ...newProductInput,
                sellingPrice: e.target.value,
              })
            }
            required
          />

          <InputField
            name="description"
            label="Description"
            placeholder="Description"
            value={newProductInput.description}
            onChange={(e) =>
              setNewProductInput({
                ...newProductInput,
                description: e.target.value,
              })
            }
            required
          />
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={closeUpdateModal}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={!productInputProgress ? UpdateProductsProduct : null}
            className="btn btn-primary btn-round"
          >
            {productInputProgress ? <TinyLoader /> : 'Continue'}
          </button>
        </DialogActions>
      </Dialog>
      <Dialog open={openAdjustModal}>
        <DialogTitle>Adjust Product</DialogTitle>
        <DialogContent>
          <OptionField
            name="operation"
            children={['Select operation...', 'ADD', 'REMOVE']}
            placeholder="ADD / SUBTRACT QUANTITY"
            label="Add / Subtract Quantity"
            onChange={(e) =>
              setAdjustInput({
                ...adjustInput,
                operation: e.target.value,
              })
            }
            required
          />

          <InputField
            type="number"
            name="quantity"
            value={adjustInput.quantity}
            placeholder="Quantity"
            label="Quantity"
            onChange={(e) =>
              setAdjustInput({
                ...adjustInput,
                quantity: e.target.value,
              })
            }
            required
          />

          <InputField
            type="text"
            name="reason"
            value={adjustInput.reason}
            placeholder="reason"
            label="Reason"
            onChange={(e) =>
              setAdjustInput({
                ...adjustInput,
                reason: e.target.value,
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setOpenAdjustModal(false);
            }}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Cancel
          </button>
          <button
            onClick={!adjustProgress ? performAdjust : null}
            className="btn btn-primary btn-round"
          >
            {adjustProgress ? <TinyLoader /> : 'Continue'}
          </button>
        </DialogActions>
      </Dialog>
      <Dialog open={openProductTypeModal}>
        <DialogTitle>Add Type</DialogTitle>
        <DialogContent>
          <OptionField
            name="category"
            value={productTypeInput.category}
            children={productCategory}
            label="Category"
            onChange={(e) =>
              setProductTypeInput({
                ...productTypeInput,
                category: e.target.value,
              })
            }
            required
          />

          <InputField
            type="text"
            name="title"
            value={productTypeInput.title}
            placeholder="Provide product type title e.g Android"
            label="Type"
            onChange={(e) =>
              setProductTypeInput({
                ...productTypeInput,
                title: e.target.value,
              })
            }
            required
          />
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={closeProductTypeModal}
            className="btn btn-danger btn-border btn-round mr-2"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={!productTypeProgress ? createProductType : null}
            className="btn btn-primary btn-round"
          >
            {productTypeProgress ? <TinyLoader /> : 'Continue'}
          </button>
        </DialogActions>
      </Dialog>
      <div className="page-inner overflow-y-auto">
        <div className="row">
          <div className="col-md-12">
            <div className="header-card">
              <div className="card-header">
                <div className="d-flex align-items-center">
                  <h4 className="card-title">Manage Products</h4>

                  <SearchField
                    value={searchText}
                    placeholder="Search Products e.g Iphone or available"
                    onChange={onSearch}
                  />
                  <Link
                    to="/inventory/sale"
                    className="btn btn-primary  btn-round ml-auto btn-large link-btn"
                  >
                    <i
                      className="fa fa-shopping-cart"
                      style={{ margin: '5px' }}
                    ></i>
                    Create Oder
                  </Link>
                </div>
              </div>
            </div>
            <div
              style={{ gap: '10px' }}
              className="d-flex align-items-start mt-0 group-action"
            >
              <button
                onClick={() => {
                  setProductTypeInput({
                    title: '',
                    description: '',
                  });
                  setOpenProductTypeModal(true);
                }}
                className="btn default-btn btn-secondary btn-round p-2"
              >
                Create Product Type
              </button>

              <button
                onClick={() => {
                  setNewProductInput({
                    title: '',
                    type: '',
                    category: '',
                    quantity: 0,
                    purchasePrice: 0,
                    sellingPrice: 0,
                    size: '',
                    description: '',
                  });
                  setOpenProductModal(true);
                }}
                className="btn default-btn btn-round p-2"
              >
                Add Product
              </button>

              <button
                onClick={() => {
                  navigate('/inventory/sales');
                }}
                className="btn default-btn btn-secondary btn-round p-2 link-btn mobile"
              >
                All Sales
              </button>
            </div>
            <br />
            <div className="row">
              {inProgress ? (
                <div className="row card">
                  <Loader></Loader>
                </div>
              ) : allInventories.length > 0 ? (
                width > 800 ? (
                  <div className="row card">
                    <div className="table-responsive table-hover">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>S/N</th>
                            <th>Title</th>
                            <th>Type</th>
                            <th>Selling price</th>
                            <th>Quantity</th>
                            <th>Description</th>
                            <th>Created</th>
                            <th>Status</th>
                            <th style={{ width: '10%' }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allInventories.map((inventory, index) => {
                            console.log(inventory);
                            return (
                              <tr key={index}>
                                <td>{++index}</td>
                                <td>
                                  <strong>{titleCase(inventory.title)}</strong>
                                </td>
                                <td>{inventory.type}</td>
                                <td>₦{toMoney(inventory.sellingPrice)}</td>
                                <td>{inventory.quantityBased ? inventory.quantity : 'N/A'}</td>
                                <td>{inventory.description ? inventory.description : 'N/A'}</td>
                                <td>{format(inventory.createdAt)}</td>
                                <td
                                  className={
                                    inventory.status === 'AVAILABLE'
                                      ? 'text-primary'
                                      : 'text-danger'
                                  }
                                >
                                  {inventory.status}
                                </td>
                                <td>
                                  <div className="form-button-action">
                                    <button
                                      type="button"
                                      data-toggle="tooltip"
                                      title=""
                                      className="btn  btn-primary  "
                                      data-original-title="Edit Task"
                                      onClick={() => {
                                        setNewProductInput({
                                          title: inventory.title,
                                          quantity: inventory.quantity,
                                          purchasePrice:
                                            inventory.purchasePrice,
                                          sellingPrice: inventory.sellingPrice,
                                          description: inventory.description,
                                        });
                                        setSelectedProduct(inventory);
                                        setOpenUpdateModal(true);
                                      }}
                                    >
                                      <i className="fa fa-edit"></i>
                                    </button>
                                    <button
                                      type="button"
                                      data-toggle="tooltip"
                                      title=""
                                      className="btn  btn-primary"
                                      data-original-title="Adjust"
                                      onClick={(e) => {
                                        setSelectedProduct(inventory);
                                        setOpenAdjustModal(true);
                                      }}
                                    >
                                      <i className="border action-icon border-primary fa fa-plus"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  allInventories.map((inventory, index) => (
                    <div
                      className="list-card-item col-xl-2 col-lg-4 col-md-4 col-12"
                      key={index}
                    >
                      <div className="image-flip">
                        <div className="mainflip flip-0">
                          <div className="frontside">
                            <div className="card">
                              {inventory.quantity > 0 ? (
                                <div className="invoice-ribbon">
                                  <div className="ribbon-inner  bg-primary">
                                    Available
                                  </div>
                                </div>
                              ) : (
                                <div className="invoice-ribbon">
                                  <div className="ribbon-inner bg-danger">
                                    Not Available
                                  </div>
                                </div>
                              )}
                              <div className="card-body text-left">
                                <h4 className="card-title text-primary">
                                  {inventory.title}
                                </h4>
                                <ul className="card-text">
                                  <li>{inventory.type}</li>
                                  <li>
                                    Left:{' '}
                                    <span className="text-danger">
                                      {inventory.quantity}
                                    </span>
                                  </li>
                                  <li>
                                    Selling Price:{' '}
                                    <span className="text-success">
                                      {' '}
                                      ₦{toMoney(inventory.sellingPrice)}
                                    </span>
                                  </li>
                                  <li>
                                    Created : {format(inventory.createdAt)}
                                  </li>
                                </ul>

                                <div className="form-button-action">
                                  <button
                                    type="button"
                                    data-toggle="tooltip"
                                    title=""
                                    className="btn  btn-primary  "
                                    data-original-title="Edit Task"
                                    onClick={() => {
                                      setNewProductInput({
                                        title: inventory.title,
                                        quantity: inventory.quantity,
                                        purchasePrice: inventory.purchasePrice,
                                        sellingPrice: inventory.sellingPrice,
                                        description: inventory.description,
                                      });
                                      setSelectedProduct(inventory);
                                      setOpenUpdateModal(true);
                                    }}
                                  >
                                    <i className="fa fa-edit"></i>
                                  </button>
                                  <button
                                    type="button"
                                    data-toggle="tooltip"
                                    title=""
                                    className="btn  btn-primary"
                                    data-original-title="Adjust"
                                    onClick={(e) => {
                                      setSelectedProduct(inventory);
                                      setOpenAdjustModal(true);
                                    }}
                                  >
                                    <i className="border action-icon border-primary fa fa-plus"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )
              ) : (
                <div className="row card text-center message-box">
                  <p>
                    {' '}
                    {searchText && searchText.length >= 3
                      ? 'Search not found'
                      : 'No product found'}
                  </p>
                </div>
              )}
            </div>
          </div>

          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-end">
              <li className="page-item">
                <button className="page-link" onClick={() => changePage(-1)}>
                  Previous
                </button>
              </li>
              {[...Array(totalPages)].map((e, i) => (
                <li className="page-item" key={i}>
                  <button
                    className={
                      i === currentPage
                        ? 'page-link btn-primary text-white'
                        : 'page-link'
                    }
                    onClick={() => changePage(i)}
                  >
                    {i}
                  </button>
                </li>
              ))}
              <li className="page-item">
                <button className="page-link" onClick={() => changePage(+1)}>
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </OtherPageBody>
  );
};

export default InventoryProducts;
