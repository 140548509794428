import Login from "../../pages/users/Login";
import Profile from "../../pages/users/Profile";
import VerifyUser from "../../pages/users/VerifyUser";
import Dashboard from "../../pages/users/Dashboard";
import AllResources from "../../pages/users/AllResources";
import AddResource from "../../pages/users/AddResource";
import ViewResource from "../../pages/users/ViewResource";
import SignUp from "../../pages/users/SignUp";
import ResetPassword from "../../pages/users/ResetPassword";
import AllUsers from "../../pages/users/AllUsers";
import SignUpBusiness from "../../pages/users/SignUpBusiness";
import VerifyResource from "../../pages/users/VerifyResource";
import RequestTracking from "../../pages/users/RequestTracking";
import AllTrackingRequests from "../../pages/users/AllTrackingRequests";
import ResourceOwnershipHistory from "../../pages/users/ResourceOwnershipHistory";
import AllProductType from "../../pages/users/AllProductType";
import AllProductCategory from "../../pages/users/AllProductCategory";
import InventorySales from "../../pages/users/InventorySales";
import InventoryAnalytics from "../../pages/users/InventoryAnalytics";
import AllProductSize from "../../pages/users/AllProductSize";
import AllBranches from "../../pages/users/AllBranches";
import AllResourceType from "../../pages/users/AllResourceType";
import InventoryProducts from "../../pages/users/InventoryProducts";
import InventoryServices from "../../pages/users/InventoryServices";
import InventoryTransactions from "../../pages/users/InventoryTransactions";
import InventorySale from "../../pages/users/InventorSale";
import ManageInvestment from "../../pages/users/ManageInvestment";
import ManageDebt from "../../pages/users/ManageDebt";
import ManageExpenses from "../../pages/users/ManageExpenses";
import RegSuccess from "../../pages/users/RegSuccess";
import CreateTransaction from "../../pages/users/CreateTransaction";

let user = "user";
let resource = "resource";
let product = "product";
let service = "service";

let routes = [
  {
    path: `${user}/register`,
    component: SignUp,
    protected: false,
  },
  {
    path: `${user}/register/business`,
    component: SignUpBusiness,
    protected: false,
  },
  {
    path: `${user}/reset-password`,
    component: ResetPassword,
    protected: false,
  },
  {
    path: `${user}/verify-account`,
    component: VerifyUser,
    protected: false,
  },
  {
    path: `${user}/profile`,
    component: Profile,
    protected: true,
  },
  {
    path: `${user}/all`,
    component: AllUsers,
    protected: true,
  },
  {
    path: `branch/all`,
    component: AllBranches,
    protected: true,
  },
  {
    path: `${user}/login`,
    component: Login,
    protected: false,
  },
  {
    path: `${user}/registration-success`,
    component: RegSuccess,
    protected: false,
  },
  {
    path: `/`,
    component: Dashboard,
    protected: true,
  },
  {
    path: `dashboard`,
    component: Dashboard,
    protected: true,
  },
  {
    path: `${resource}/all`,
    component: AllResources,
    protected: true,
  },
  {
    path: `${resource}/add`,
    component: AddResource,
    protected: true,
  },
  {
    path: `${resource}/history`,
    component: ResourceOwnershipHistory,
    protected: true,
  },
  {
    path: `${product}/categories`,
    component: AllProductCategory,
    protected: true,
  },
  {
    path: `${product}/types`,
    component: AllProductType,
    protected: true,
  },
  {
    path: `${resource}/types`,
    component: AllResourceType,
    protected: true,
  },
  {
    path: `${product}/sizes`,
    component: AllProductSize,
    protected: true,
  },
  {
    path: `${resource}/verification`,
    component: VerifyResource,
    protected: true,
  },
  {
    path: `${resource}/view/:id`,
    component: ViewResource,
    protected: true,
  },
  {
    path: `tracking/request`,
    component: RequestTracking,
    protected: true,
  },
  {
    path: `tracking/requests`,
    component: AllTrackingRequests,
    protected: true,
  },
  {
    path: `inventory/products`,
    component: InventoryProducts,
    protected: true,
  },
  {
    path: `fund/investment`,
    component: ManageInvestment,
    protected: true,
  },
  {
    path: `debt`,
    component: ManageDebt,
    protected: true,
  },
  {
    path: `expenses`,
    component: ManageExpenses,
    protected: true,
  },
  {
    path: `inventory/sales`,
    component: InventorySales,
    protected: true,
  },
  {
    path: `inventory/sale`,
    component: InventorySale,
    protected: true,
  },
  {
    path: `inventory/services`,
    component: InventoryServices,
    protected: true,
  },

  {
    path: `inventory/transactions`,
    component: InventoryTransactions,
    protected: true,
  },

  {
    path: `/service/oder/:type`,
    component: CreateTransaction,
    protected: true,
  },
  {
    path: `inventory/analytics`,
    component: InventoryAnalytics,
    protected: true,
  },
];
export default routes;
