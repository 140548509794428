import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { format } from 'timeago.js';
import { inventoryService } from '../../service/inventory.service';
import OtherPageBody from './OtherPageBody';
import Loader from './components/loaders/Loader';
import SearchField from './components/inputs/SearchField';
import { Link } from 'react-router-dom';
import toMoney from 'number-to-money';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { UserContext } from '../../Access';
import { useContext } from 'react';
import { UserRole } from '../../enums/UserRole';
import InputField from './components/inputs/InputField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useReactToPrint } from 'react-to-print';
import { titleCase } from 'title-case';
import { TransactionType } from '../../enums/TransactionType';
import signature from './../../assets/img/signature.png';
import OptionField from './components/select/OptionField';

const InventoryTransactions = () => {
  const userSession = useContext(UserContext);
  const user = userSession.user;

  const [response, setResponse] = useState([]);
  const [downloadURL, setDownloadURL] = useState();
  const [inProgress, setInProgress] = useState(false);
  const [openTransactionDetailModal, setOpenTransactionDetailModal] = useState();
  const [transactionDetail, setTransactionDetail] = useState();
  const [searchText, setSearchText] = useState('');
  const [totalPages, setTotalPage] = useState();
  const [summary, setSummary] = useState();
  let [currentPage, setCurrentPage] = useState(0);
  const { width } = useWindowDimensions();
  const [input, setInput] = useState({ from: '', to: '', transactionType: '' });
  const dataRef = useRef();

  useEffect(() => {
    getAllTransactions();
  }, []);

  const handlePrint = useReactToPrint({
    content: () => dataRef.current,
  });

  const getAllTransactions = () => {
    const request = {
      from: input.from,
      to: input.to,
      transactionType: input.transactionType,
    };
    setInProgress(true);
    inventoryService
      .getAllTransactions(request)
      .then(async (result) => {
        setInProgress(false);
        if (result.data.success) {
          setSummary(result.data.data.summary)
          setResponse(result.data.data.page);
        }
        const download = await inventoryService.downloadTransactions(currentPage, request)
        if (download.data.success)
          setDownloadURL(download.data.data.url)


      })
      .catch((e) => {
        setResponse([]);
        setInProgress(false);
      });
  };

  const changePage = (action) => {
    if (action === -1) {
      if (currentPage > 0) currentPage--;
    } else if (action === +1) {
      if (currentPage < totalPages) currentPage++;
    } else {
      currentPage = action;
    }
    setCurrentPage(currentPage);
    if (searchText && searchText.length >= 3) performSearch();
    else getAllTransactions();
  };

  const performSearch = () => {
    if (searchText && searchText.length >= 3) {

      const request = {
        from: input.from,
        to: input.to,
        transactionType: input.transactionType
      };
      setInProgress(true);
      inventoryService
        .searchTransactions(request, currentPage, searchText)
        .then((result) => {
          setInProgress(false);
          if (result.data.success) {
            const { page } = result.data.data;
            const { data } = result.data;
            setResponse(page);
            if (page.length > 0) {
              setTotalPage(data.totalPages);
              setSummary(data.summary)
              setCurrentPage(data.currentPage);
            }
          } else {
            setResponse([]);
          }
        })
        .catch((e) => {
          setResponse([]);
          setInProgress(false);
        });
    }
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
    performSearch();
  };

  return (
    <OtherPageBody>
      <ToastContainer />
      <Dialog open={openTransactionDetailModal && transactionDetail !== null}>
        <DialogTitle>Transaction Receipt</DialogTitle>
        <DialogContent>
          {transactionDetail ? (
            <div className="row">
              <div className="col-md-12">
                <div className="panel panel-default invoice" ref={dataRef}>
                  <div className="panel-body">
                    <div className="invoice-ribbon">
                      <div className="ribbon-inner  bg-primary">Paid</div>
                    </div>
                    <div className="row  p-5">
                      <div className="row">
                        <div className={width > 800 ? 'col-sm-6  mt-5' : 'col-sm-6'}>
                          <h3 className="marginright">{user.business}</h3>
                          <span className="marginright">
                            {transactionDetail.createdBy}
                          </span>
                        </div>
                        <div className={width > 800 ? 'col-sm-6  mt-5 top-right' : 'col-sm-6 top-right'}>
                          <h3 className="marginright">
                            REF-{transactionDetail.code}
                          </h3>
                          <span className="marginright">
                            {new Date(transactionDetail.createdAt).toISOString()}
                          </span>
                        </div>
                      </div>
                      <hr />
                      <div className="mt-4  mb-3">
                        <span className="marginright">
                          {titleCase(transactionDetail.transactionType.toLowerCase())} transaction by{' '}
                          <strong>{transactionDetail.customerName}</strong> with phone
                          number{' '}
                          <strong>{transactionDetail.customerPhoneNumber}</strong>

                          {transactionDetail.transactionType === TransactionType.WITHDRAWAL ?
                            <>
                              <strong>{transactionDetail.serviceType}</strong>
                              &nbsp;via {titleCase(transactionDetail.channel)} &nbsp;
                              {format(transactionDetail.createdAt)}
                            </> : ''}

                          {transactionDetail.transactionType === TransactionType.DEPOSIT ?
                            <>
                              &nbsp;deposited to {transactionDetail.bankName},  {transactionDetail.accountNumber}
                              <strong> {transactionDetail.accountName}</strong> {format(transactionDetail.createdAt)}
                            </> : ''}

                          {transactionDetail.transactionType === TransactionType.BILLPAYMENT ?
                            <>
                              &nbsp; to <strong>{transactionDetail.beneficiary}</strong> about {format(transactionDetail.createdAt)}
                            </> : ''}
                        </span>
                      </div>

                      <div className="row">
                        <div className="summary-wrapper col-sm-6">
                          {transactionDetail.customerPicture ?
                            <img src={transactionDetail.customerPicture} className="card customer-pic" alt="customer" />
                            : <></>}
                          <img src={signature} alt="signature mt-3" />
                        </div>
                        <div className="col-sm-6 text-right pull-right invoice-total mt-5">
                          <p>Subtotal : ₦{toMoney(transactionDetail.amount)}</p>
                          <p>
                            Discount : ₦{toMoney(0)}
                          </p>
                          <p>VAT : ₦0 </p>
                          <p>
                            Total : ₦
                            {toMoney(
                              transactionDetail.amount
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <p>Unable to generate data</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-secondary btn-round mr-2"
            onClick={() => handlePrint()}
          >
            Print
          </button>

          <button
            className="btn btn-danger  btn-round mr-2"
            onClick={() => {

              setOpenTransactionDetailModal(false)
            }}
          >
            Okay
          </button>
        </DialogActions>
      </Dialog>
      <div className="page-inner">
        <div className="row">
          <div className="col-md-12">
            <div className="header-card">
              <div className="card-header">
                <div className="d-flex align-items-center">
                  <h4 className="card-title">All Transactions</h4>
                  <SearchField
                    value={searchText}
                    placeholder="Search Sales e.g POS or barbing saloon"
                    onChange={onSearch}
                    onClick={performSearch}
                  />
                  <Link
                    to="/service/oder/withdrawal"
                    className="btn btn-primary  btn-round ml-auto btn-large link-btn"
                  >
                    <i className="fa fa-plus" style={{ margin: '5px' }}></i>
                    Create Transaction
                  </Link>
                </div>
              </div>
            </div>
            <div className="filter-container d-flex align-item-center text-align-center">
              <InputField
                type="date"
                name="from"
                value={input.from}
                placeholder="From"
                label="From"
                onChange={(e) =>
                  setInput({
                    ...input,
                    from: e.target.value,
                  })
                }
              /><InputField
                type="date"
                name="to"
                value={input.to}
                placeholder="To"
                label="To"
                onChange={(e) =>
                  setInput({
                    ...input,
                    to: e.target.value,
                  })
                }
              />
              <OptionField
                children={['All', 'Deposit', 'Withdrawal', 'BillPayment']}
                name="transactionType"
                value={input.transactionType}
                placeholder="Sort by transaction type"
                label="Transaction Type"
                onChange={(e) =>
                  setInput({
                    ...input,
                    transactionType: e.target.value,
                  })
                }
              />
              <div className="filter-button text-align-right">
                <button
                  type="button"
                  onClick={() => {
                    getAllTransactions()
                  }}
                  className="btn default-btn btn-primary btn-large p-2"
                >

                  Filter
                </button>
              </div>
            </div>
            <div className="row data-wrapper">
              {summary && summary.length > 0 ?
                < div className="p-3">
                  Total: <strong>{summary[0].amount ? toMoney(summary[0].amount) : 0}</strong> &nbsp;&nbsp;{user.role !== UserRole.User ? <> Revenue: <strong>{summary[0].revenue ? toMoney(summary[0].revenue) : 0}</strong></> : <></>}
                </div>
                : <></>}
              {inProgress ? (
                <div className="row card">
                  <Loader></Loader>
                </div>
              ) : response.length > 0 ? (
                width > 800 ? (
                  <div className="row card">
                    <div className="card-body">
                      <table
                        id="add-row"
                        className="display table table-striped table-hover"
                      >
                        <thead>
                          <tr>
                            <th>S/n</th>
                            <th>Customer Name</th>
                            <th>Phone Number</th>
                            <th>Transaction Type</th>
                            <th>Amount</th>
                            <th>Revenue</th>
                            <th>Date</th>
                            <th>#</th>
                          </tr>
                        </thead>

                        <tbody>
                          {response.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{++index}</td>
                                <td>{item.customerName}</td>
                                <td>{item.customerPhoneNumber}</td>
                                <td>{item.transactionType}</td>
                                <td>₦{toMoney(item.amount)}</td>
                                <td>₦{user.role === UserRole.User ? '****' : item.revenue ? toMoney(item.revenue) : '0'}</td>
                                <td>{format(item.createdAt)}</td>
                                <td>
                                  <div className="form-button-action">
                                    <button
                                      type="button"
                                      data-toggle="tooltip"
                                      title=""
                                      className="btn btn-primary"
                                      data-original-title="view"
                                      onClick={() => {
                                        setTransactionDetail(item);
                                        setOpenTransactionDetailModal(true);
                                      }}
                                    >
                                      <i className="fa fa-eye"></i> View Receipt
                                    </button>
                                  </div>

                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  response.map((item, index) => {
                    return (
                      <div
                        className="list-card-item col-xl-2 col-lg-4 col-md-4 col-12"
                        key={index}
                      >
                        <div className="image-flip">
                          <div className="mainflip flip-0">
                            <div className="frontside">
                              <div className="card">
                                <div className="invoice-ribbon">
                                  <div className="ribbon-inner  bg-primary">
                                    Paid
                                  </div>
                                </div>
                                <div className="card-body text-left">
                                  <h4 className="card-title text-primary">
                                    {item.name}
                                  </h4>
                                  <ul className="card-text">
                                    <li>
                                      <h2><strong>{item.transactionType}</strong></h2>
                                    </li>
                                    <li>
                                      <strong>{item.customerName}</strong>
                                    </li>
                                    <li>
                                      Total Amount:{' '}
                                      <span className="text-danger">
                                        ₦{toMoney(item.amount)}
                                      </span>
                                    </li>
                                    <li>
                                      Total Revenue:{' '}
                                      <span className="text-success">
                                        ₦{user.role === UserRole.User ? '****' : item.revenue ? toMoney(item.revenue) : '0'}
                                      </span>
                                    </li>
                                    <li>
                                      Discount:{' '}
                                      <span className="text-danger">
                                        {' '}
                                        ₦{item.discount ? item.discount : 0}
                                      </span>
                                    </li>
                                    <li>Created :<span className="text-primary">{format(item.createdAt)}</span></li>
                                    <li>
                                      Status :{' '}
                                      <span className="text-success">
                                        Successful
                                      </span>
                                    </li>
                                  </ul>
                                  <div className="form-button-action">
                                    <button
                                      type="button"
                                      data-toggle="tooltip"
                                      title=""
                                      className="btn btn-primary"
                                      data-original-title="view"
                                      onClick={() => {
                                        setTransactionDetail(item);
                                        setOpenTransactionDetailModal(true);
                                      }}
                                    >
                                      <i className="fa fa-industry"></i> View Receipt
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )
              ) : (
                <div className="row card">
                  <div className="text-center message-box">
                    <p>No Transaction found</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-end">
            <li className="page-item p-3">
              <a href={downloadURL} download>
                <button className="btn btn-secondary">
                  <i className="fa fa-download"></i> Download
                </button>
              </a>
            </li>
            <li className="page-item">
              <button className="page-link" onClick={() => changePage(-1)}>
                Previous
              </button>
            </li>
            {[...Array(totalPages)].map((e, i) => (
              <li className="page-item" key={i}>
                <button
                  className={
                    i === currentPage
                      ? 'page-link btn-primary text-white'
                      : 'page-link'
                  }
                  onClick={() => changePage(i)}
                >
                  {i + 1}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button className="page-link" onClick={() => changePage(+1)}>
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </OtherPageBody >
  );
};

export default InventoryTransactions;
